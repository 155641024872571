@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
/*! recurrence editor theme wise definitions*/
/*! Schedule component's material theme definitions and variables */
.e-schedule .e-schedule-toolbar .e-icon-prev::before {
  content: '\e937';
}

.e-schedule .e-schedule-toolbar .e-icon-next::before {
  content: '\e956';
}

.e-schedule .e-schedule-toolbar .e-icon-add::before {
  content: '\e963';
}

.e-schedule .e-schedule-toolbar .e-icon-today::before {
  content: '\e322';
}

.e-schedule .e-schedule-toolbar .e-icon-down-arrow::before {
  content: '\e969';
}

.e-schedule .e-schedule-toolbar .e-icon-day::before {
  content: '\e31b';
}

.e-schedule .e-schedule-toolbar .e-icon-week::before {
  content: '\e315';
}

.e-schedule .e-schedule-toolbar .e-icon-workweek::before {
  content: '\e314';
}

.e-schedule .e-schedule-toolbar .e-icon-month::before {
  content: '\e93c';
}

.e-schedule .e-schedule-toolbar .e-icon-month-agenda::before {
  content: '\e313';
}

.e-schedule .e-schedule-toolbar .e-icon-agenda::before {
  content: '\e31d';
}

.e-schedule .e-schedule-toolbar .e-icon-year::before {
  content: '\ea8e';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-year-vertical::before {
  content: '\e423';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-year-horizontal::before {
  content: '\e422';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-day::before {
  content: '\ea85';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-week::before {
  content: '\ea88';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-workweek::before {
  content: '\ea8b';
}

.e-schedule .e-schedule-toolbar .e-icon-timeline-month::before {
  content: '\ea8e';
}

.e-schedule .e-schedule-toolbar.e-toolbar .e-popup-down-icon::before {
  content: '\e984';
}

.e-schedule .e-schedule-toolbar.e-toolbar .e-popup-up-icon::before {
  content: '\e984';
}

.e-schedule .e-schedule-toolbar.e-rtl .e-icon-prev::before {
  content: '\e956';
}

.e-schedule .e-schedule-toolbar.e-rtl .e-icon-next::before {
  content: '\e937';
}

.e-schedule .e-schedule-resource-toolbar .e-icon-menu::before {
  content: '\e99a';
}

.e-schedule .e-schedule-resource-toolbar .e-icon-next::before {
  content: '\e956';
}

.e-schedule .e-close-icon::before {
  content: '\e7fc';
}

.e-schedule .e-block-indicator::before {
  content: '\e22a';
}

.e-schedule .e-appointment .e-recurrence-icon::before {
  content: '\e308';
}

.e-schedule .e-appointment .e-recurrence-edit-icon::before {
  content: '\e30a';
}

.e-schedule .e-appointment .e-up-icon::before {
  content: '\e306';
}

.e-schedule .e-appointment .e-down-icon::before {
  content: '\e304';
}

.e-schedule .e-appointment .e-left-icon::before {
  content: '\e302';
}

.e-schedule .e-appointment .e-right-icon::before {
  content: '\e300';
}

.e-schedule .e-vertical-view .e-all-day-cells .e-all-day-appointment-section::before {
  content: '\e916';
}

.e-schedule.e-rtl .e-appointment .e-left-icon::before {
  content: '\e300';
}

.e-schedule.e-rtl .e-appointment .e-right-icon::before {
  content: '\e302';
}

.e-schedule .e-resource-tree-icon::before {
  content: '\e22f';
}

.e-schedule-dialog.e-device .e-back-icon::before {
  content: '\e977';
}

.e-schedule-dialog.e-device .e-save-icon::before {
  content: '\e98e';
}

.e-schedule-dialog.e-device .e-recurrence-container .e-recurrence-edit-button .e-recurrence-edit.e-icons::before {
  content: '\e944';
}

.e-schedule-dialog.e-device.e-rtl .e-back-icon::before {
  content: '\e85f';
}

.e-quick-popup-wrapper .e-edit-icon::before {
  content: '\e891';
}

.e-quick-popup-wrapper .e-delete-icon::before {
  content: '\e94a';
}

.e-quick-popup-wrapper .e-close-icon::before {
  content: '\e7fc';
}

.e-quick-popup-wrapper .e-time-icon::before {
  content: '\e20c';
}

.e-quick-popup-wrapper .e-location-icon::before {
  content: '\e30c';
}

.e-quick-popup-wrapper .e-time-zone-icon::before {
  content: '\e30e';
}

.e-quick-popup-wrapper .e-description-icon::before {
  content: '\e30d';
}

.e-quick-popup-wrapper .e-resource-icon::before {
  content: '\e424';
}

.e-quick-popup-wrapper .e-date-time-icon::before {
  content: '\e421';
}

.e-more-popup-wrapper.e-device .e-close-icon::before {
  content: '\e7fc';
}

/*! schedule component layout */
.e-schedule {
  display: block;
  outline: medium none;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-schedule .e-schedule-toolbar {
  background: #fafafa;
  border: 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
  min-height: 42px;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #e3165b;
}

.e-schedule .e-schedule-toolbar .e-toolbar-pop {
  background: #fafafa;
  overflow: auto;
}

.e-schedule .e-schedule-toolbar .e-tbar-btn {
  background: #fafafa;
}

.e-schedule .e-schedule-toolbar .e-tbar-btn:hover, .e-schedule .e-schedule-toolbar .e-tbar-btn:focus {
  background-color: #e0e0e0;
}

.e-schedule .e-schedule-toolbar .e-tbar-btn:active {
  background: #e0e0e0;
}

.e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items {
  background: #fafafa;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 42px;
  min-height: 42px;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos > div {
  height: inherit;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon.e-icon-down-arrow {
  font-size: 10px;
  padding-top: 0;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:hover {
  background: #e0e0e0;
  border-radius: 100%;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:focus {
  background: #e0e0e0;
  border-radius: 100%;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-day .e-icon-day,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-week .e-icon-week,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-work-week .e-icon-workweek,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-month .e-icon-month,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-year .e-icon-year,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-agenda .e-icon-agenda,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-month-agenda .e-icon-month-agenda,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-day .e-icon-timeline-day,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-week .e-icon-timeline-week,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-work-week .e-icon-timeline-workweek,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-month .e-icon-timeline-month,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-year .e-icon-timeline-year-vertical,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-timeline-year .e-icon-timeline-year-horizontal,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-add .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-today .e-icon-day,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-today .e-icon-today + .e-tbar-btn-text {
  display: none;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-hidden {
  display: none;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-prev .e-icon-prev,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-next .e-icon-next {
  font-size: 14px;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text {
  font-size: 14px;
  text-transform: initial;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding-left: 0;
}

.e-schedule .e-schedule-toolbar.e-rtl .e-hor-nav {
  background: #fafafa;
  border-left: 0;
  border-right: 1px solid #f5f5f5;
}

.e-schedule .e-schedule-toolbar.e-rtl .e-hor-nav:not(.e-nav-active):hover {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-schedule-toolbar .e-hor-nav {
  background: #fafafa;
  border-left: 1px solid #f5f5f5;
}

.e-schedule .e-schedule-toolbar .e-hor-nav::after {
  content: '';
}

.e-schedule .e-schedule-toolbar .e-hor-nav:active {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-schedule-toolbar .e-hor-nav:active::after {
  content: '';
}

.e-schedule .e-schedule-toolbar .e-hor-nav:hover {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-schedule-toolbar .e-hor-nav:focus {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule.e-device .e-schedule-toolbar {
  height: 56px;
  min-height: 56px;
}

.e-schedule.e-device .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:hover, .e-schedule.e-device .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:focus {
  height: calc(100% - 20px);
}

.e-schedule.e-device .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 56px;
  min-height: 56px;
}

.e-schedule.e-device .e-schedule-toolbar .e-toolbar-items.e-tbar-pos > div {
  height: inherit;
}

.e-schedule.e-device .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
  font-size: 14px;
}

.e-schedule.e-device .e-schedule-toolbar .e-date-range .e-tbar-btn .e-tbar-btn-text {
  font-size: 14px;
}

.e-schedule .e-schedule-resource-toolbar {
  background: #fafafa;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
  min-height: 42px;
  padding: 10px;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-menu .e-icon-menu {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  padding: 7px;
  padding-left: 4px;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-menu.e-disable {
  display: none;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-level-title {
  display: -ms-flexbox;
  display: flex;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-level-title .e-resource-name {
  font-size: 14px;
  padding: 5px;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-level-title .e-resource-name:not(:last-child) {
  opacity: .6;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-level-title .e-icon-next {
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  line-height: 18px;
  opacity: .4;
  padding: 5px;
}

.e-schedule .e-schedule-resource-toolbar.e-popup-close {
  display: none;
}

.e-schedule .e-schedule-resource-toolbar.e-popup-open {
  display: block;
}

.e-schedule .e-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-text-center {
  text-align: center;
}

.e-schedule .e-table-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  vertical-align: top;
}

.e-schedule .e-table-container.e-translate {
  transition: transform .3s ease 0s;
}

.e-schedule .e-resource-tree-popup {
  background: #fff;
  box-shadow: 0 3px 4px 0.2px rgba(0, 0, 0, 0.4);
  height: 100%;
  position: absolute;
  width: 250px;
}

.e-schedule .e-resource-tree-popup .e-resource-tree {
  height: 100%;
  overflow-y: auto;
}

.e-schedule .e-resource-tree-popup .e-resource-tree.e-treeview .e-text-content {
  padding: 0 0 0 12px;
}

.e-schedule .e-resource-tree-popup-overlay.e-enable {
  background-color: #383838;
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  width: 100%;
}

.e-schedule .e-new-event {
  background-color: #3f51b5;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 100%;
  opacity: .9;
  position: relative;
  width: 100%;
  z-index: 1;
}

.e-schedule .e-new-event .e-title {
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
  overflow-wrap: break-word;
  padding: 3px;
  position: absolute;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
}

.e-schedule .e-schedule-table {
  border: 0 none;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.e-schedule .e-schedule-table > tbody > tr > th,
.e-schedule .e-schedule-table > tbody > tr > td {
  box-sizing: border-box;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-schedule .e-outer-table > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}

.e-schedule .e-content-wrap {
  position: relative;
}

.e-schedule .e-content-wrap .e-day-wrapper {
  width: 100%;
}

.e-schedule .e-content-wrap,
.e-schedule .e-scroll-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.e-schedule .e-hide-childs > * {
  display: none;
}

.e-schedule .e-recurrence-icon,
.e-schedule .e-recurrence-edit-icon {
  font-size: 11px;
}

.e-schedule .e-left-icon,
.e-schedule .e-right-icon {
  font-size: 8px;
}

.e-schedule .e-m-date {
  cursor: pointer;
  font-size: 20px;
}

.e-schedule .e-m-date:hover {
  text-decoration: underline;
}

.e-schedule .e-m-day {
  font-size: 12px;
}

.e-schedule.e-device .e-m-date:hover {
  text-decoration: none;
}

.e-schedule .e-block-appointment {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.6);
  cursor: default;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-block-appointment .e-subject {
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 4px;
  white-space: normal;
  word-wrap: break-word;
}

.e-schedule .e-block-indicator {
  color: rgba(0, 0, 0, 0.6);
  float: right;
  padding: 0 5px;
  z-index: 1;
}

.e-schedule.e-rtl .e-block-indicator {
  float: left;
}

.e-schedule .e-timeline-view .e-block-indicator,
.e-schedule .e-timeline-month-view .e-block-indicator {
  position: absolute;
  top: 3px;
}

.e-schedule .e-appointment {
  opacity: 1;
  transition: opacity 100ms linear;
}

.e-schedule .e-appointment .e-subject.e-disable {
  display: none;
}

.e-schedule .e-appointment .e-inline-appointment {
  z-index: 1;
}

.e-schedule .e-appointment .e-inline-subject {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 4px;
  width: 100%;
}

.e-schedule .e-appointment.e-event-action {
  opacity: .4;
}

.e-schedule .e-appointment.e-allow-select {
  pointer-events: none;
}

.e-schedule .e-read-only {
  opacity: .8;
}

.e-schedule.e-event-action .e-appointment:not(.e-schedule-event-clone),
.e-schedule.e-event-action .e-block-appointment {
  pointer-events: none;
}

.e-schedule.e-event-action .e-drag-clone,
.e-schedule.e-event-action .e-timeline-view .e-drag-clone {
  cursor: move;
}

.e-schedule.e-event-action .e-drag-clone .e-top-handler,
.e-schedule.e-event-action .e-drag-clone .e-bottom-handler,
.e-schedule.e-event-action .e-timeline-view .e-drag-clone .e-top-handler,
.e-schedule.e-event-action .e-timeline-view .e-drag-clone .e-bottom-handler {
  pointer-events: none;
}

.e-schedule.e-event-action .e-vertical-view .e-appointment-wrapper .e-resize-clone {
  cursor: ns-resize;
}

.e-schedule.e-event-action .e-timeline-view .e-resize-clone,
.e-schedule.e-event-action .e-timeline-month-view .e-resize-clone,
.e-schedule.e-event-action .e-all-day-appointment-wrapper .e-resize-clone {
  cursor: ew-resize;
}

.e-schedule.e-device .e-appointment {
  overflow: hidden;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-top-bottom-resize {
  height: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-left-right-resize {
  border-radius: 50%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-top-handler .e-top-bottom-resize {
  margin-top: -8px;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-bottom-handler .e-top-bottom-resize {
  margin-top: 8px;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-left-handler .e-left-right-resize {
  margin-left: -8px;
}

.e-schedule.e-device .e-appointment.e-appointment-border .e-right-handler .e-left-right-resize {
  margin-left: 8px;
}

.e-schedule .e-event-resize {
  position: absolute;
}

.e-schedule .e-event-resize.e-left-handler, .e-schedule .e-event-resize.e-right-handler {
  height: 100%;
  width: 10px;
}

.e-schedule .e-event-resize.e-left-handler {
  cursor: ew-resize;
  float: left;
  left: 0;
}

.e-schedule .e-event-resize.e-right-handler {
  cursor: e-resize;
  float: right;
  right: 0;
}

.e-schedule .e-event-resize.e-top-handler, .e-schedule .e-event-resize.e-bottom-handler {
  height: 10px;
  width: 100%;
}

.e-schedule .e-event-resize.e-top-handler {
  cursor: ns-resize;
  top: 0;
}

.e-schedule .e-event-resize.e-bottom-handler {
  bottom: 0;
  cursor: ns-resize;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-content-table,
.e-schedule .e-vertical-view.e-timescale-disable .e-content-wrap {
  height: 100%;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  height: 54px;
  margin-bottom: 2px;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  padding: 2px 4px 0 2px;
  text-align: left;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-inner-wrap {
  -ms-flex: auto;
      flex: auto;
  overflow: hidden;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-subject {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  min-height: 18px;
  overflow: hidden;
  overflow-wrap: break-word;
  padding-top: 1px;
  white-space: normal;
  word-wrap: break-word;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-time,
.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-location {
  font-size: 11px;
  overflow: hidden;
  padding-top: 1px;
  text-overflow: ellipsis;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-recurrence-icon,
.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-recurrence-edit-icon {
  line-height: 54px;
  padding: 0 5px;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-left-icon,
.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-right-icon {
  line-height: 54px;
  padding: 0 2px;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment.e-appointment-border, .e-schedule .e-vertical-view.e-timescale-disable .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment.e-allow-select {
  pointer-events: none;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-appointment-wrapper {
  position: absolute;
  width: 0;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-work-cells {
  height: auto;
  vertical-align: top;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-more-indicator {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 11px;
  line-height: 11px;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  text-overflow: ellipsis;
}

.e-schedule .e-vertical-view.e-timescale-disable .e-more-indicator:focus {
  text-decoration: underline;
}

.e-schedule .e-vertical-view .e-left-indent {
  width: 85px;
}

.e-schedule .e-vertical-view .e-left-indent .e-all-day-cells {
  position: relative;
}

.e-schedule .e-vertical-view .e-date-header-wrap table col,
.e-schedule .e-vertical-view .e-content-wrap table col {
  width: 36px;
}

.e-schedule .e-vertical-view .e-resource-cells {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}

.e-schedule .e-vertical-view .e-header-cells {
  font-size: 12px;
  height: 60px;
  padding: 5px;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  display: table;
  font-size: 13px;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-date {
  cursor: pointer;
  display: table;
  font-size: 18px;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-date:hover {
  text-decoration: underline;
}

.e-schedule .e-vertical-view .e-header-cells.e-disable-dates .e-header-day {
  cursor: default;
  opacity: .35;
}

.e-schedule .e-vertical-view .e-header-cells.e-disable-dates .e-header-date {
  cursor: default;
  opacity: .35;
}

.e-schedule .e-vertical-view .e-header-cells.e-disable-dates .e-header-date:hover {
  text-decoration: none;
}

.e-schedule .e-vertical-view .e-row-count-wrapper {
  display: block;
  height: 100%;
  pointer-events: none;
  position: relative;
}

.e-schedule .e-vertical-view .e-row-count-wrapper .e-more-indicator {
  bottom: 0;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  overflow: hidden;
  overflow-wrap: normal;
  padding: 2px 4px;
  pointer-events: visible;
  position: absolute;
  text-overflow: ellipsis;
  width: 100%;
}

.e-schedule .e-vertical-view .e-row-count-wrapper .e-more-indicator:focus {
  text-decoration: underline;
}

.e-schedule .e-vertical-view .e-row-count-wrapper.e-disable {
  display: none;
}

.e-schedule .e-vertical-view .e-all-day-appointment-section {
  bottom: 4px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: block;
  font-size: 11px;
  height: 25px;
  left: 0;
  line-height: 25px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
  width: 25px;
}

.e-schedule .e-vertical-view .e-all-day-appointment-section.e-disable {
  display: none;
}

.e-schedule .e-vertical-view .e-all-day-appointment-section.e-appointment-expand {
  transform: rotate(0deg);
}

.e-schedule .e-vertical-view .e-all-day-appointment-section.e-appointment-collapse {
  transform: rotate(180deg);
}

.e-schedule .e-vertical-view .e-all-day-appointment-section:hover, .e-schedule .e-vertical-view .e-all-day-appointment-section:focus {
  background: #e0e0e0;
  border-radius: 100%;
}

.e-schedule .e-vertical-view .e-appointment-hide {
  opacity: 0;
}

.e-schedule .e-vertical-view .e-all-day-cells {
  height: 0;
  padding: 0;
  text-align: center;
  vertical-align: top;
}

.e-schedule .e-vertical-view .e-all-day-cells:first-child.e-animate {
  transition: 250ms ease-out;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  font-size: 12px;
  height: 36px;
  text-align: center;
}

.e-schedule .e-vertical-view .e-work-cells {
  width: 36px;
}

.e-schedule .e-vertical-view .e-work-cells.e-disable-dates {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-vertical-view .e-work-cells.e-disable-dates:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-vertical-view .e-current-time {
  font-size: 11px;
}

.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time,
.e-schedule .e-vertical-view .e-previous-timeline,
.e-schedule .e-vertical-view .e-current-timeline {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}

.e-schedule .e-vertical-view .e-previous-timeline,
.e-schedule .e-vertical-view .e-current-timeline {
  z-index: 1;
}

.e-schedule .e-vertical-view .e-date-header-wrap,
.e-schedule .e-vertical-view .e-time-cells-wrap {
  overflow: hidden;
}

.e-schedule .e-vertical-view .e-content-wrap,
.e-schedule .e-vertical-view .e-time-cells-wrap {
  position: relative;
}

.e-schedule .e-vertical-view .e-timeline-wrapper,
.e-schedule .e-vertical-view .e-day-wrapper,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper {
  height: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment:not(.e-schedule-event-clone) {
  cursor: default;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  line-height: 20px;
  overflow: hidden;
  padding: 1px 0 1px 4px;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-subject {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-time {
  display: block;
  font-size: 11px;
  padding: 1px 4px 2px 0;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-recurrence-icon,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-recurrence-edit-icon,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-left-icon,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details .e-right-icon {
  line-height: 20px;
  padding: 0 2px;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment.e-appointment-border, .e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment:not(.e-schedule-event-clone) {
  cursor: default;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-appointment-details {
  -ms-flex: auto;
      flex: auto;
  padding: 0 4px;
  text-align: left;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-subject {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  min-height: 18px;
  overflow: hidden;
  overflow-wrap: break-word;
  padding-top: 4px;
  white-space: normal;
  word-wrap: break-word;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-time,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-location {
  font-size: 11px;
  overflow: hidden;
  padding-top: 1px;
  text-overflow: ellipsis;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-time {
  display: block;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-recurrence-icon,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-recurrence-edit-icon {
  bottom: 5px;
  display: block;
  float: right;
  height: auto;
  left: auto;
  position: absolute;
  right: 5px;
  width: auto;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-indicator {
  display: block;
  margin: 0 45%;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-up-icon,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-down-icon {
  font-size: 8px;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-up-icon {
  margin-top: 3px;
  top: 0;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-down-icon {
  bottom: 6px;
  position: absolute;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment.e-appointment-border, .e-schedule .e-vertical-view .e-day-wrapper .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-vertical-view.e-day-view .e-header-date {
  cursor: default;
}

.e-schedule .e-vertical-view.e-day-view .e-header-date:hover {
  text-decoration: none;
}

.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-day {
  font-size: 14px;
}

.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-date {
  font-size: 20px;
}

.e-schedule.e-device .e-vertical-view .e-header-cells .e-header-date:hover {
  text-decoration: none;
}

.e-schedule.e-device .e-vertical-view .e-left-indent {
  width: 35px;
}

.e-schedule.e-device .e-vertical-view .e-clone-time-indicator,
.e-schedule.e-device .e-vertical-view .e-current-time {
  font-size: 9px;
}

.e-schedule.e-device .e-vertical-view .e-time-cells-wrap {
  float: left;
  width: 35px;
}

.e-schedule.e-device .e-vertical-view .e-time-cells-wrap table td {
  font-size: 11px;
}

.e-schedule.e-device .e-vertical-view .e-day-wrapper .e-appointment .e-subject {
  font-weight: 500;
  min-height: auto;
}

.e-schedule.e-rtl {
  text-align: right;
}

.e-schedule.e-rtl .e-vertical-view.e-timescale-disable .e-appointment-details {
  text-align: right;
}

.e-schedule.e-rtl .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .e-appointment-details {
  padding: 1px 4px 1px 0;
}

.e-schedule.e-rtl .e-vertical-view .e-day-wrapper .e-appointment .e-appointment-details {
  line-height: 15px;
  text-align: right;
}

.e-schedule.e-rtl .e-vertical-view .e-day-wrapper .e-appointment .e-subject {
  font-weight: 500;
  margin-left: 15px;
  margin-right: auto;
}

.e-schedule.e-rtl .e-vertical-view .e-day-wrapper .e-appointment .e-recurrence-icon,
.e-schedule.e-rtl .e-vertical-view .e-day-wrapper .e-appointment .e-recurrence-edit-icon {
  float: left;
  left: 5px;
  right: auto;
}

.e-schedule.e-rtl.e-device .e-vertical-view .e-time-cells-wrap {
  float: right;
}

@media screen and (max-width: 480px) {
  .e-schedule .e-vertical-view .e-left-indent {
    width: 35px;
  }
}

.e-schedule .e-month-view .e-left-indent,
.e-schedule .e-month-agenda-view .e-left-indent {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 36px;
}

.e-schedule .e-month-view .e-week-number-wrapper,
.e-schedule .e-month-agenda-view .e-week-number-wrapper {
  overflow: hidden;
  position: relative;
}

.e-schedule .e-month-view .e-week-number-wrapper .e-schedule-table,
.e-schedule .e-month-agenda-view .e-week-number-wrapper .e-schedule-table {
  height: 100%;
}

.e-schedule .e-month-view .e-week-number-wrapper .e-week-number,
.e-schedule .e-month-agenda-view .e-week-number-wrapper .e-week-number {
  color: rgba(0, 0, 0, 0.54);
  padding-top: 4px;
  text-align: center;
  vertical-align: top;
  width: 36px;
}

.e-schedule .e-month-view .e-content-wrap table tr:last-child td {
  border-bottom-width: 0;
}

.e-schedule .e-month-view .e-date-header-wrap {
  font-size: 13px;
  overflow: hidden;
}

.e-schedule .e-month-view .e-week-number {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: 70px;
}

.e-schedule .e-month-view .e-content-wrap {
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
}

.e-schedule .e-month-view .e-date-header-wrap table col {
  width: 36px;
}

.e-schedule .e-month-view .e-resource-cells {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}

.e-schedule .e-month-view .e-header-cells {
  cursor: default;
  font-weight: 400;
  height: 30px;
  padding: 3px;
}

.e-schedule .e-month-view .e-content-table {
  height: 100%;
}

.e-schedule .e-month-view .e-work-cells {
  height: 70px;
  vertical-align: top;
  width: 36px;
}

.e-schedule .e-month-view .e-work-cells.e-disable-date .e-date-header {
  visibility: hidden;
}

.e-schedule .e-month-view .e-work-cells.e-disable-dates {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-month-view .e-work-cells.e-disable-dates .e-date-header {
  cursor: default;
  opacity: .35;
}

.e-schedule .e-month-view .e-work-cells.e-disable-dates .e-date-header:hover {
  text-decoration: none;
}

.e-schedule .e-month-view .e-work-cells.e-disable-dates:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-month-view .e-date-header {
  cursor: pointer;
  display: table;
  height: 20px;
  line-height: 20px;
  margin: 3px 3px 2px;
  text-align: center;
}

.e-schedule .e-month-view .e-date-header:hover {
  text-decoration: underline;
}

.e-schedule .e-month-view .e-appointment-wrapper {
  position: absolute;
}

.e-schedule .e-month-view .e-new-event {
  height: calc(100% - 25px);
}

.e-schedule .e-month-view .e-block-indicator {
  margin-top: -20px;
}

.e-schedule .e-month-view .e-inline-appointment {
  z-index: 1;
}

.e-schedule .e-month-view .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-month-view .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  line-height: 20px;
  overflow: hidden;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  padding: 1px 2px;
  text-overflow: ellipsis;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-inline-subject {
  padding: 4px;
  text-align: center;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-time {
  font-size: 11px;
  padding: 1.5px;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-recurrence-icon,
.e-schedule .e-month-view .e-appointment .e-appointment-details .e-recurrence-edit-icon,
.e-schedule .e-month-view .e-appointment .e-appointment-details .e-left-icon,
.e-schedule .e-month-view .e-appointment .e-appointment-details .e-right-icon {
  line-height: 22px;
  padding: 0 2px;
}

.e-schedule .e-month-view .e-appointment.e-appointment-border, .e-schedule .e-month-view .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-month-view .e-appointment.e-allow-select {
  pointer-events: none;
}

.e-schedule .e-month-view .e-appointment .e-subject {
  padding: 2px;
  width: 100%;
}

.e-schedule .e-month-view .e-more-indicator {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 11px;
  line-height: 11px;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  text-overflow: ellipsis;
}

.e-schedule .e-month-view .e-more-indicator:focus {
  text-decoration: underline;
}

.e-schedule.e-rtl .e-month-view .e-left-indent {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right-width: 0;
}

.e-schedule.e-rtl .e-month-view .e-week-number {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right-width: 0;
}

.e-schedule .e-year-view .e-calendar-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar {
  border: 0;
  -ms-flex: 0 25%;
      flex: 0 25%;
  max-width: 100%;
  min-width: 260px;
  padding: 10px;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-header .e-title {
  cursor: default;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-content span.e-day {
  display: block;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-other-month {
  color: rgba(0, 0, 0, 0.54);
  pointer-events: visible;
  -ms-touch-action: auto;
      touch-action: auto;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-other-month.e-today {
  opacity: .5;
}

.e-schedule .e-year-view .e-calendar-wrapper .e-month-calendar.e-calendar .e-appointment {
  background-color: #3f51b5;
  border-radius: 50%;
  height: 5px;
  margin-left: 13px;
  margin-top: -6px;
  position: absolute;
  width: 5px;
}

.e-schedule .e-year-view .e-date-header-wrap {
  font-size: 13px;
  overflow: hidden;
}

.e-schedule .e-year-view .e-date-header-wrap table col {
  width: 36px;
}

.e-schedule .e-year-view .e-date-header-wrap table td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.e-schedule .e-year-view .e-date-header-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-year-view .e-resource-cells {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}

.e-schedule .e-year-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-year-view .e-calendar-table td:not(.e-cell) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-year-view .e-resource .e-month-calendar {
  max-width: 100%;
  min-width: 100%;
}

.e-schedule .e-timeline-year-view .e-date-header-wrap,
.e-schedule .e-timeline-year-view .e-month-header-wrapper {
  overflow: hidden;
  position: relative;
}

.e-schedule .e-timeline-year-view .e-date-header-wrap table col,
.e-schedule .e-timeline-year-view .e-content-wrap table col {
  width: 60px;
}

.e-schedule .e-timeline-year-view .e-resource-left-td,
.e-schedule .e-timeline-year-view .e-date-header-wrap table td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.e-schedule .e-timeline-year-view .e-resource-left-td.e-current-day,
.e-schedule .e-timeline-year-view .e-date-header-wrap table td.e-current-day {
  color: #e3165b;
}

.e-schedule .e-timeline-year-view .e-resource-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  overflow: hidden;
  padding: 4px 15px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-year-view .e-resource-left-td {
  vertical-align: middle;
  width: 200px;
}

.e-schedule .e-timeline-year-view .e-left-indent-wrap .e-resource-cells,
.e-schedule .e-timeline-year-view .e-date-header-wrap .e-resource-cells {
  height: 40px;
}

.e-schedule .e-timeline-year-view .e-resource-column-wrap {
  overflow: hidden;
  position: relative;
}

.e-schedule .e-timeline-year-view .e-resource-column-wrap .e-resource-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
  height: 70px;
  padding-left: 15px;
  padding-right: 0;
  vertical-align: middle;
}

.e-schedule .e-timeline-year-view .e-resource-column-wrap .e-resource-cells.e-child-node {
  background-color: #fff;
}

.e-schedule .e-timeline-year-view .e-resource-column-wrap .e-resource-cells:focus {
  background-color: #f5f5f5;
}

.e-schedule .e-timeline-year-view .e-resource-column-table {
  height: 100%;
}

.e-schedule .e-timeline-year-view .e-resource-column-table.e-auto-height {
  height: auto;
}

.e-schedule .e-timeline-year-view .e-resource-tree-icon {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  float: left;
  font-family: 'e-icons';
  font-size: 12px;
  padding: 4px 7px;
}

.e-schedule .e-timeline-year-view .e-resource-collapse {
  transform: rotate(90deg);
}

.e-schedule .e-timeline-year-view .e-work-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-year-view .e-work-cells:hover:not(.e-other-month) {
  background: #f5f5f5;
  color: #000;
}

.e-schedule .e-timeline-year-view .e-work-cells.e-work-days {
  background-color: #fff;
}

.e-schedule .e-timeline-year-view .e-work-cells.e-other-month {
  color: rgba(0, 0, 0, 0.54);
}

.e-schedule .e-timeline-year-view .e-work-cells.e-current-day .e-date-header {
  background-color: #e3165b;
  border-radius: 50%;
  color: #fff;
  margin: 2px;
  width: 20px;
}

.e-schedule .e-timeline-year-view .e-work-cells.e-selected-cell {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-year-view .e-work-cells.e-selected-cell .e-more-indicator {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-year-view .e-work-cells.e-selected-cell:hover {
  background-color: #e0e0e0;
}

.e-schedule .e-timeline-year-view .e-content-table {
  height: 100%;
}

.e-schedule .e-timeline-year-view .e-content-table.e-auto-height {
  height: auto;
}

.e-schedule .e-timeline-year-view .e-left-indent td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 0 0;
  vertical-align: middle;
}

.e-schedule .e-timeline-year-view .e-left-indent tr:last-child td {
  border-width: 0 1px 1px 0;
}

.e-schedule .e-timeline-year-view.e-horizontal .e-left-indent {
  padding: 0;
  width: 100px;
}

.e-schedule .e-timeline-year-view.e-vertical .e-left-indent,
.e-schedule .e-timeline-year-view.e-vertical .e-left-indent .e-header-cells {
  width: 50px;
}

.e-schedule .e-timeline-year-view .e-month-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  text-align: center;
}

.e-schedule .e-timeline-year-view .e-month-header.e-current-day {
  color: #e3165b;
}

.e-schedule .e-timeline-year-view .e-left-indent,
.e-schedule .e-timeline-year-view .e-header-cells {
  cursor: default;
  font-weight: 400;
  height: 30px;
  padding: 0;
  text-align: center;
}

.e-schedule .e-timeline-year-view .e-month-header,
.e-schedule .e-timeline-year-view .e-work-cells {
  height: 70px;
}

.e-schedule .e-timeline-year-view .e-header-cells,
.e-schedule .e-timeline-year-view .e-work-cells {
  width: 60px;
}

.e-schedule .e-timeline-year-view .e-work-cells {
  vertical-align: top;
}

.e-schedule .e-timeline-year-view .e-work-cells .e-date-header {
  cursor: pointer;
  display: table;
  height: 20px;
  line-height: 20px;
  margin: 3px 3px 2px;
  text-align: center;
  width: 10px;
}

.e-schedule .e-timeline-year-view .e-work-cells .e-date-header:hover {
  text-decoration: underline;
}

.e-schedule .e-timeline-year-view .e-event-table {
  position: absolute;
  top: 0;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment-wrapper {
  position: absolute;
}

.e-schedule .e-timeline-year-view .e-event-table .e-inline-appointment {
  z-index: 1;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment.e-hidden {
  display: none;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  line-height: 20px;
  overflow: hidden;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-subject {
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  padding: 1px 2px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-time,
.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-location {
  font-size: 11px;
  overflow: hidden;
  padding: 1.5px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-recurrence-icon,
.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-recurrence-edit-icon {
  line-height: 38px;
  padding: 0 5px;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-indicator {
  display: block;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-left-icon,
.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-right-icon {
  font-size: 10px;
  line-height: 38px;
  padding-right: 7px;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment .e-right-icon {
  padding-right: 5px;
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment.e-appointment-border, .e-schedule .e-timeline-year-view .e-event-table .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-timeline-year-view .e-event-table .e-appointment.e-allow-select {
  pointer-events: none;
}

.e-schedule .e-timeline-year-view .e-event-table .e-more-indicator {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 11px;
  line-height: 11px;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-year-view .e-event-table .e-more-indicator.e-hidden {
  display: none;
}

.e-schedule .e-timeline-year-view .e-event-table .e-more-indicator:focus {
  text-decoration: underline;
}

.e-schedule.e-device .e-year-view .e-month-calendar {
  max-width: 100%;
  min-width: 100%;
}

.e-schedule .e-timeline-view .e-content-wrap,
.e-schedule .e-timeline-view .e-content-table,
.e-schedule .e-timeline-month-view .e-content-wrap,
.e-schedule .e-timeline-month-view .e-content-table {
  height: 100%;
}

.e-schedule .e-timeline-view .e-date-header-wrap,
.e-schedule .e-timeline-month-view .e-date-header-wrap {
  font-size: 13px;
  overflow: hidden;
  position: relative;
}

.e-schedule .e-timeline-view .e-event-table,
.e-schedule .e-timeline-month-view .e-event-table {
  position: absolute;
  top: 0;
}

.e-schedule .e-timeline-view .e-date-header-wrap table col,
.e-schedule .e-timeline-view .e-content-wrap table col,
.e-schedule .e-timeline-month-view .e-date-header-wrap table col,
.e-schedule .e-timeline-month-view .e-content-wrap table col {
  width: 50px;
}

.e-schedule .e-timeline-view .e-date-header-wrap table tbody td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td {
  height: 36px;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-schedule .e-timeline-view .e-date-header-wrap table tbody td.e-time-slots,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td.e-time-slots {
  overflow: inherit;
}

.e-schedule .e-timeline-view .e-date-header-wrap table tbody td > span,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span {
  position: relative;
}

.e-schedule .e-timeline-view .e-navigate:hover,
.e-schedule .e-timeline-month-view .e-navigate:hover {
  cursor: pointer;
  text-decoration: underline;
}

.e-schedule .e-timeline-view .e-navigate:focus,
.e-schedule .e-timeline-month-view .e-navigate:focus {
  text-decoration: underline;
}

.e-schedule .e-timeline-view .e-work-cells,
.e-schedule .e-timeline-month-view .e-work-cells {
  height: 60px;
}

.e-schedule .e-timeline-view .e-work-cells.e-disable-dates,
.e-schedule .e-timeline-month-view .e-work-cells.e-disable-dates {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-timeline-view .e-work-cells.e-disable-dates:hover,
.e-schedule .e-timeline-month-view .e-work-cells.e-disable-dates:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-timeline-view .e-resource-text,
.e-schedule .e-timeline-month-view .e-resource-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  overflow: hidden;
  padding: 4px 15px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-view .e-resource-left-td,
.e-schedule .e-timeline-month-view .e-resource-left-td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
  vertical-align: middle;
  width: 200px;
}

.e-schedule .e-timeline-view .e-resource-cells,
.e-schedule .e-timeline-month-view .e-resource-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
  height: 60px;
  padding-left: 15px;
  padding-right: 0;
  vertical-align: middle;
}

.e-schedule .e-timeline-view .e-resource-cells:focus,
.e-schedule .e-timeline-month-view .e-resource-cells:focus {
  background-color: #f5f5f5;
}

.e-schedule .e-timeline-view .e-resource-cells:focus .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-cells:focus .e-resource-tree-icon {
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #000;
}

.e-schedule .e-timeline-view .e-resource-column-wrap,
.e-schedule .e-timeline-month-view .e-resource-column-wrap {
  overflow: hidden;
  position: relative;
}

.e-schedule .e-timeline-view .e-resource-column-table,
.e-schedule .e-timeline-month-view .e-resource-column-table {
  height: 100%;
}

.e-schedule .e-timeline-view .e-auto-height,
.e-schedule .e-timeline-month-view .e-auto-height {
  height: auto;
}

.e-schedule .e-timeline-view.e-ignore-whitespace .e-work-cells,
.e-schedule .e-timeline-view.e-ignore-whitespace .e-resource-cells,
.e-schedule .e-timeline-month-view.e-ignore-whitespace .e-work-cells,
.e-schedule .e-timeline-month-view.e-ignore-whitespace .e-resource-cells {
  height: 42px;
}

.e-schedule .e-timeline-view .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-tree-icon {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  float: left;
  font-family: 'e-icons';
  font-size: 12px;
  padding: 4px 7px;
}

.e-schedule .e-timeline-view .e-resource-collapse,
.e-schedule .e-timeline-month-view .e-resource-collapse {
  transform: rotate(90deg);
}

.e-schedule .e-timeline-view .e-child-node,
.e-schedule .e-timeline-month-view .e-child-node {
  background-color: #fff;
}

.e-schedule .e-timeline-view .e-appointment-wrapper,
.e-schedule .e-timeline-month-view .e-appointment-wrapper {
  position: absolute;
}

.e-schedule .e-timeline-view .e-inline-appointment,
.e-schedule .e-timeline-month-view .e-inline-appointment {
  z-index: 1;
}

.e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  background: #3f51b5;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  overflow: hidden;
  position: absolute;
}

.e-schedule .e-timeline-view .e-appointment .e-appointment-details,
.e-schedule .e-timeline-month-view .e-appointment .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
      flex: auto;
  overflow: hidden;
  padding: 0 4px;
}

.e-schedule .e-timeline-view .e-appointment .e-appointment-details .e-inner-wrap,
.e-schedule .e-timeline-month-view .e-appointment .e-appointment-details .e-inner-wrap {
  -ms-flex: auto;
      flex: auto;
  overflow: hidden;
}

.e-schedule .e-timeline-view .e-appointment .e-subject,
.e-schedule .e-timeline-month-view .e-appointment .e-subject {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  min-height: 18px;
  overflow: hidden;
  padding-top: 4px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-view .e-appointment .e-time,
.e-schedule .e-timeline-view .e-appointment .e-location,
.e-schedule .e-timeline-month-view .e-appointment .e-time,
.e-schedule .e-timeline-month-view .e-appointment .e-location {
  font-size: 11px;
  overflow: hidden;
  padding-top: 1px;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-view .e-appointment .e-time,
.e-schedule .e-timeline-month-view .e-appointment .e-time {
  display: block;
}

.e-schedule .e-timeline-view .e-appointment .e-disable,
.e-schedule .e-timeline-month-view .e-appointment .e-disable {
  display: none;
}

.e-schedule .e-timeline-view .e-appointment .e-recurrence-icon,
.e-schedule .e-timeline-view .e-appointment .e-recurrence-edit-icon,
.e-schedule .e-timeline-month-view .e-appointment .e-recurrence-icon,
.e-schedule .e-timeline-month-view .e-appointment .e-recurrence-edit-icon {
  line-height: 38px;
  padding: 0 5px;
}

.e-schedule .e-timeline-view .e-appointment .e-indicator,
.e-schedule .e-timeline-month-view .e-appointment .e-indicator {
  display: block;
}

.e-schedule .e-timeline-view .e-appointment .e-left-icon,
.e-schedule .e-timeline-view .e-appointment .e-right-icon,
.e-schedule .e-timeline-month-view .e-appointment .e-left-icon,
.e-schedule .e-timeline-month-view .e-appointment .e-right-icon {
  font-size: 10px;
  line-height: 38px;
  padding-right: 7px;
}

.e-schedule .e-timeline-view .e-appointment .e-right-icon,
.e-schedule .e-timeline-month-view .e-appointment .e-right-icon {
  padding-right: 5px;
}

.e-schedule .e-timeline-view .e-appointment.e-appointment-border, .e-schedule .e-timeline-view .e-appointment:focus,
.e-schedule .e-timeline-month-view .e-appointment.e-appointment-border,
.e-schedule .e-timeline-month-view .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-schedule .e-timeline-view .e-appointment.e-allow-select,
.e-schedule .e-timeline-month-view .e-appointment.e-allow-select {
  pointer-events: none;
}

.e-schedule .e-timeline-view .e-more-indicator,
.e-schedule .e-timeline-month-view .e-more-indicator {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 11px;
  line-height: 11px;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  text-overflow: ellipsis;
}

.e-schedule .e-timeline-view .e-more-indicator:focus,
.e-schedule .e-timeline-month-view .e-more-indicator:focus {
  text-decoration: underline;
}

.e-schedule .e-timeline-view .e-navigate {
  font-size: 13px;
}

.e-schedule .e-timeline-view .e-clone-time-indicator,
.e-schedule .e-timeline-view .e-current-time {
  bottom: 0;
  position: absolute;
}

.e-schedule .e-timeline-view .e-current-timeline {
  position: absolute;
  top: 0;
}

.e-schedule .e-timeline-month-view .e-date-header-wrap table col,
.e-schedule .e-timeline-month-view .e-content-wrap table col {
  width: 70px;
}

.e-schedule .e-virtual-scroll .e-content-table {
  position: absolute;
  transform: translateY(0);
}

.e-schedule .e-virtual-scroll .e-content-wrap.e-transition {
  transition: all 1000ms linear;
}

.e-schedule.e-rtl .e-timeline-view .e-appointment .e-left-icon,
.e-schedule.e-rtl .e-timeline-view .e-appointment .e-right-icon,
.e-schedule.e-rtl .e-timeline-month-view .e-appointment .e-left-icon,
.e-schedule.e-rtl .e-timeline-month-view .e-appointment .e-right-icon {
  padding: 0 0 0 5px;
}

.e-schedule.e-rtl .e-timeline-view .e-inline-appointment,
.e-schedule.e-rtl .e-timeline-month-view .e-inline-appointment {
  z-index: 1;
}

.e-schedule.e-rtl .e-timeline-view .e-resource-left-td,
.e-schedule.e-rtl .e-timeline-month-view .e-resource-left-td {
  border-width: 0 0 1px 1px;
}

.e-schedule.e-rtl .e-timeline-view .e-resource-cells,
.e-schedule.e-rtl .e-timeline-month-view .e-resource-cells {
  border-width: 0 0 1px 1px;
  padding-right: 15px;
}

.e-schedule.e-rtl .e-timeline-view .e-resource-tree-icon,
.e-schedule.e-rtl .e-timeline-month-view .e-resource-tree-icon {
  float: right;
}

.e-schedule.e-rtl .e-timeline-view .e-appointment.e-schedule-event-clone .e-appointment-details .e-inner-wrap,
.e-schedule.e-rtl .e-timeline-month-view .e-appointment.e-schedule-event-clone .e-appointment-details .e-inner-wrap {
  text-align: right;
}

.e-schedule.e-device .e-month-view .e-date-header-wrap,
.e-schedule.e-device .e-timeline-view .e-date-header-wrap,
.e-schedule.e-device .e-timeline-month-view .e-date-header-wrap {
  font-size: 13px;
}

.e-schedule.e-device .e-month-view .e-date-header-wrap td,
.e-schedule.e-device .e-timeline-view .e-date-header-wrap td,
.e-schedule.e-device .e-timeline-month-view .e-date-header-wrap td {
  border-left-width: 0;
  border-right-width: 0;
}

.e-schedule.e-device .e-month-view .e-content-wrap,
.e-schedule.e-device .e-timeline-view .e-content-wrap,
.e-schedule.e-device .e-timeline-month-view .e-content-wrap {
  font-size: 14px;
}

.e-schedule.e-device .e-month-view .e-date-header:hover,
.e-schedule.e-device .e-timeline-view .e-date-header:hover,
.e-schedule.e-device .e-timeline-month-view .e-date-header:hover {
  text-decoration: none;
}

.e-schedule.e-device .e-month-view .e-more-indicator,
.e-schedule.e-device .e-timeline-view .e-more-indicator,
.e-schedule.e-device .e-timeline-month-view .e-more-indicator {
  font-size: 12px;
}

.e-schedule.e-device .e-month-view .e-navigate:hover,
.e-schedule.e-device .e-timeline-view .e-navigate:hover,
.e-schedule.e-device .e-timeline-month-view .e-navigate:hover {
  text-decoration: none;
}

.e-schedule .e-month-agenda-view .e-schedule-resource-toolbar .e-resource-menu {
  margin-top: 6px;
}

.e-schedule .e-month-agenda-view .e-schedule-resource-toolbar .e-resource-menu.e-disable {
  display: none;
}

.e-schedule .e-month-agenda-view .e-schedule-resource-toolbar .e-resource-level-title .e-icon-next {
  margin-top: 4px;
}

.e-schedule .e-month-agenda-view .e-week-number-wrapper .e-week-number {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 8px;
}

.e-schedule .e-month-agenda-view .e-wrapper-container {
  position: relative;
}

.e-schedule .e-month-agenda-view .e-week-number-wrapper .e-schedule-table,
.e-schedule .e-month-agenda-view .e-content-table {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}

.e-schedule .e-month-agenda-view .e-resource-column {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  vertical-align: top;
  width: 75px;
}

.e-schedule .e-month-agenda-view .e-resource-name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 26px;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
}

.e-schedule .e-month-agenda-view .e-day-padding {
  padding-left: 8px;
}

.e-schedule .e-month-agenda-view .e-day-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-month-agenda-view .e-date-header-wrap {
  font-size: 13px;
  overflow: hidden;
}

.e-schedule .e-month-agenda-view .e-content-wrap {
  font-size: 13px;
}

.e-schedule .e-month-agenda-view .e-header-cells {
  cursor: default;
  font-weight: 400;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.e-schedule .e-month-agenda-view .e-work-cells {
  height: 40px;
  position: relative;
  vertical-align: top;
}

.e-schedule .e-month-agenda-view .e-work-cells.e-disable-dates {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-month-agenda-view .e-work-cells.e-disable-dates:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.e-schedule .e-month-agenda-view .e-date-header {
  height: 24px;
  line-height: 24.5px;
  margin: 4px auto 0;
  text-align: center;
  width: 24px;
}

.e-schedule .e-month-agenda-view .e-appointment-container {
  min-height: 40px;
}

.e-schedule .e-month-agenda-view .e-appointment-wrap {
  background-color: #fff;
  overflow: auto;
  padding: 0 8px;
}

.e-schedule .e-month-agenda-view .e-appointment {
  border-left: 3px solid #3f51b5;
  border-right: 0;
  cursor: default;
  padding: 0 8px;
}

.e-schedule .e-month-agenda-view .e-appointment:hover {
  background: #f5f5f5;
}

.e-schedule .e-month-agenda-view .e-appointment.e-appointment-border, .e-schedule .e-month-agenda-view .e-appointment:focus {
  background: #e0e0e0;
}

.e-schedule .e-month-agenda-view .e-subject-wrap {
  display: -ms-flexbox;
  display: flex;
}

.e-schedule .e-month-agenda-view .e-subject {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-month-agenda-view .e-recurrence-icon,
.e-schedule .e-month-agenda-view .e-recurrence-edit-icon {
  color: rgba(0, 0, 0, 0.54);
  line-height: 26px;
  padding: 0 10px;
}

.e-schedule .e-month-agenda-view .e-date-time {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-month-agenda-view .e-location {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 26px;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
}

.e-schedule .e-month-agenda-view .e-no-event {
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  font-size: 14px;
  height: 100%;
  padding: 8px 4px;
}

.e-schedule .e-month-agenda-view .e-agenda-item.e-month-agenda-view {
  padding: 10px 0;
}

.e-schedule .e-month-agenda-view .e-agenda-parent.e-month-agenda-view {
  margin: 0;
  padding: 0;
}

.e-schedule .e-month-agenda-view .e-appointment-indicator {
  background: #3f51b5;
  border-radius: 50%;
  height: 6px;
  left: 0;
  margin: 1px auto;
  width: 6px;
}

.e-schedule.e-rtl .e-month-agenda-view .e-left-indent {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right-width: 0;
}

.e-schedule.e-rtl .e-month-agenda-view .e-week-number {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right-width: 0;
}

.e-schedule.e-rtl .e-month-agenda-view .e-appointment {
  border-left: 0;
  border-right: 3px solid #3f51b5;
}

.e-schedule.e-rtl .e-month-agenda-view .e-resource-column {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
  vertical-align: top;
  width: 75px;
}

.e-schedule.e-rtl .e-month-agenda-view .e-day-padding {
  padding-right: 8px;
}

.e-schedule.e-device .e-month-agenda-view .e-date-header-wrap {
  font-size: 13px;
}

.e-schedule.e-device .e-month-agenda-view .e-content-wrap {
  font-size: 14px;
}

.e-schedule .e-agenda-view {
  background-color: #fff;
}

.e-schedule .e-agenda-view .e-content-wrap table td:first-child,
.e-schedule .e-agenda-view .e-date-column {
  vertical-align: top;
  width: 75px;
}

.e-schedule .e-agenda-view .e-resource-column {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  vertical-align: top;
  width: 75px;
}

.e-schedule .e-agenda-view .e-month-header {
  background: #3f51b5;
  height: 100px;
  padding-top: 12px;
  vertical-align: top;
}

.e-schedule .e-agenda-view .e-month-header .e-date-header {
  color: #fff;
  font-size: 18px;
}

.e-schedule .e-agenda-view .e-day-date-header {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  padding: 10px 32px 0 16px;
}

.e-schedule .e-agenda-view .e-day-date-header .e-m-date {
  font-size: 18px;
}

.e-schedule .e-agenda-view .e-day-date-header .e-m-day {
  font-size: 13px;
}

.e-schedule .e-agenda-view .e-day-date-header.e-current-day {
  color: #e3165b;
}

.e-schedule .e-agenda-view .e-day-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-agenda-view .e-date-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-agenda-view .e-day-padding {
  padding-left: 8px;
}

.e-schedule .e-agenda-view .e-date-header {
  font-size: 20px;
}

.e-schedule .e-agenda-view .e-day-header {
  font-size: 12px;
}

.e-schedule .e-agenda-view .e-subject-wrap {
  display: -ms-flexbox;
  display: flex;
}

.e-schedule .e-agenda-view .e-active-appointment-agenda .e-subject {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-agenda-view .e-active-appointment-agenda .e-date-time {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-agenda-view .e-active-appointment-agenda .e-resource-name {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-agenda-view .e-active-appointment-agenda .e-recurrence-icon,
.e-schedule .e-agenda-view .e-active-appointment-agenda .e-recurrence-edit-icon {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-agenda-view .e-active-appointment-agenda .e-location {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-agenda-view .e-subject {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-agenda-view .e-resource-name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 26px;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
}

.e-schedule .e-agenda-view .e-recurrence-icon,
.e-schedule .e-agenda-view .e-recurrence-edit-icon {
  color: rgba(0, 0, 0, 0.87);
  line-height: 21px;
  padding: 0 10px;
}

.e-schedule .e-agenda-view .e-date-time {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-agenda-view .e-location {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 23px;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
}

.e-schedule .e-agenda-view .e-agenda-item {
  padding: 10px 0;
}

.e-schedule .e-agenda-view .e-agenda-parent {
  margin: 0;
  padding: 0;
}

.e-schedule .e-agenda-view .e-appointment {
  border-left: 3px solid #3f51b5;
  border-right: 0;
  cursor: default;
  padding: 0 8px;
}

.e-schedule .e-agenda-view .e-appointment:hover {
  background: #f5f5f5;
}

.e-schedule .e-agenda-view .e-appointment.e-appointment-border, .e-schedule .e-agenda-view .e-appointment:focus {
  background: #e0e0e0;
}

.e-schedule .e-agenda-view .e-appointment.e-template {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.e-schedule .e-agenda-view .e-no-event {
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  font-size: 14px;
  line-height: 52px;
  padding: 0 11px;
}

.e-schedule .e-agenda-view .e-empty-event {
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  font-size: 14px;
  line-height: 52px;
  padding: 0 11px;
}

.e-schedule.e-device .e-agenda-view .e-m-date,
.e-schedule.e-device .e-month-agenda-view .e-m-date {
  font-size: 20px;
}

.e-schedule.e-device .e-agenda-view .e-resource-name,
.e-schedule.e-device .e-agenda-view .e-m-day,
.e-schedule.e-device .e-month-agenda-view .e-resource-name,
.e-schedule.e-device .e-month-agenda-view .e-m-day {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}

.e-schedule.e-rtl .e-agenda-view .e-appointment {
  border-left: 0;
  border-right: 3px solid #3f51b5;
}

.e-schedule.e-rtl .e-agenda-view .e-resource-column {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
  vertical-align: top;
  width: 75px;
}

.e-schedule.e-rtl .e-agenda-view .e-day-padding {
  padding-right: 8px;
}

.e-schedule.e-rtl .e-agenda-view .e-date-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
}

.e-schedule.e-read-only .e-toolbar-item.e-add.e-overflow-show {
  display: none;
}

.e-bigger .e-schedule .e-schedule-toolbar,
.e-bigger.e-schedule .e-schedule-toolbar {
  height: 56px;
  min-height: 56px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text,
.e-bigger.e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
  font-size: 14px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-item.e-date-range .e-tbar-btn-text,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-item.e-date-range .e-tbar-btn-text {
  font-size: 16px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-icon-down-arrow,
.e-bigger.e-schedule .e-schedule-toolbar .e-icon-down-arrow {
  font-size: 10px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-schedule .e-toolbar .e-hor-nav,
.e-bigger.e-schedule .e-schedule-toolbar .e-schedule .e-toolbar .e-hor-nav {
  min-height: 56px;
  min-width: 32px;
  z-index: 0;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-item .e-tbar-btn-text,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-item .e-tbar-btn-text {
  line-height: inherit;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items {
  min-height: 56px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item {
  min-height: 56px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator),
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 34px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-separator,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 15px);
  margin: 7.5px 3px;
  min-height: 27px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-icons,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-icons {
  min-width: 24px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn,
.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: auto;
  line-height: 25px;
  margin: 4px 0;
  min-height: 0;
  min-width: 34px;
  padding: 0 1.5px;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons,
.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons {
  padding: 0;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-icon-right,
.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-icon-right,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right {
  padding: 0;
}

.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn .e-btn-icon,
.e-bigger .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-btn-icon,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item button.e-btn .e-btn-icon,
.e-bigger.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-btn-icon {
  height: 25px;
}

.e-bigger .e-schedule .e-more-popup-wrapper .e-header-day,
.e-bigger.e-schedule .e-more-popup-wrapper .e-header-day {
  font-size: 14px;
}

.e-bigger .e-schedule .e-vertical-view .e-header-cells .e-header-day,
.e-bigger.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  font-size: 14px;
}

.e-bigger .e-schedule .e-vertical-view .e-header-cells .e-header-date,
.e-bigger.e-schedule .e-vertical-view .e-header-cells .e-header-date {
  font-size: 20px;
}

.e-bigger .e-schedule .e-vertical-view .e-resource-cells,
.e-bigger.e-schedule .e-vertical-view .e-resource-cells {
  font-size: 14px;
}

.e-bigger .e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-bigger.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  font-size: 13px;
}

.e-bigger .e-schedule .e-vertical-view .e-clone-time-indicator,
.e-bigger .e-schedule .e-vertical-view .e-current-time,
.e-bigger.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-bigger.e-schedule .e-vertical-view .e-current-time {
  font-size: 12px;
}

.e-bigger .e-schedule.e-device .e-vertical-view .e-time-cells-wrap table td,
.e-bigger.e-schedule.e-device .e-vertical-view .e-time-cells-wrap table td {
  font-size: 11px;
}

.e-bigger .e-schedule.e-device .e-vertical-view .e-clone-time-indicator,
.e-bigger .e-schedule.e-device .e-vertical-view .e-current-time,
.e-bigger.e-schedule.e-device .e-vertical-view .e-clone-time-indicator,
.e-bigger.e-schedule.e-device .e-vertical-view .e-current-time {
  font-size: 9px;
}

.e-bigger .e-schedule .e-month-view .e-header-cells,
.e-bigger.e-schedule .e-month-view .e-header-cells {
  height: 35px;
}

.e-bigger .e-schedule .e-month-view .e-resource-cells,
.e-bigger .e-schedule .e-month-view .e-date-header-wrap,
.e-bigger.e-schedule .e-month-view .e-resource-cells,
.e-bigger.e-schedule .e-month-view .e-date-header-wrap {
  font-size: 14px;
}

.e-bigger .e-schedule .e-month-view .e-content-wrap,
.e-bigger.e-schedule .e-month-view .e-content-wrap {
  font-size: 14px;
}

.e-bigger .e-schedule .e-month-view .e-m-date,
.e-bigger.e-schedule .e-month-view .e-m-date {
  font-size: 20px;
}

.e-bigger .e-schedule .e-month-view .e-resource-name,
.e-bigger .e-schedule .e-month-view .e-m-day,
.e-bigger.e-schedule .e-month-view .e-resource-name,
.e-bigger.e-schedule .e-month-view .e-m-day {
  font-size: 14px;
}

/*! schedule event tooltip */
.e-schedule-event-tooltip .e-subject {
  font-size: 12px;
}

.e-bigger .e-schedule-event-tooltip .e-subject,
.e-schedule-event-tooltip.e-bigger .e-subject {
  font-size: 14px;
  font-weight: 500;
}

.e-dialog.e-quick-dialog.e-following-events-dialog {
  width: 420px;
}

@media screen and (max-width: 767px) {
  .e-dialog.e-quick-dialog.e-following-events-dialog {
    width: 289px;
  }
}

.e-dialog.e-quick-dialog {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 320px;
}

.e-dialog.e-quick-dialog .e-disable {
  display: none;
}

.e-dialog.e-quick-dialog .e-quick-dialog-cancel {
  box-shadow: none;
}

.e-dialog.e-quick-dialog.e-rtl {
  text-align: right;
}

.e-bigger .e-dialog.e-quick-dialog {
  min-width: 370px;
}

/*! schedule event window */
.e-schedule-dialog .e-disable {
  display: none;
}

.e-schedule-dialog .e-icon-disable {
  opacity: .5;
}

.e-schedule-dialog .e-dlg-header-content {
  padding-bottom: 8px;
}

.e-schedule-dialog .e-dlg-content {
  padding-bottom: 12px;
  position: relative;
}

.e-schedule-dialog .e-event-delete {
  float: left;
}

.e-schedule-dialog .e-subject-container,
.e-schedule-dialog .e-start-container,
.e-schedule-dialog .e-start-time-zone-container,
.e-schedule-dialog .e-description-label {
  padding-right: 8px;
}

.e-schedule-dialog .e-location-container,
.e-schedule-dialog .e-end-container,
.e-schedule-dialog .e-end-time-zone-container {
  padding-left: 8px;
}

.e-schedule-dialog .e-all-day-container {
  padding-right: 16px;
}

.e-schedule-dialog .e-time-zone-row {
  display: -ms-flexbox;
  display: flex;
  height: 0;
  margin-bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  transition: .3s ease-in-out;
  width: 100%;
}

.e-schedule-dialog .e-time-zone-row .e-start-time-zone-container,
.e-schedule-dialog .e-time-zone-row .e-end-time-zone-container {
  display: none;
  transform: translateY(-100%);
}

.e-schedule-dialog .e-time-zone-row.e-enable {
  height: 50px;
  margin-bottom: 12px;
}

.e-schedule-dialog .e-time-zone-row.e-enable .e-start-time-zone-container,
.e-schedule-dialog .e-time-zone-row.e-enable .e-end-time-zone-container {
  display: block;
  transform: translateY(0);
}

.e-schedule-dialog .e-title-location-row,
.e-schedule-dialog .e-start-end-row,
.e-schedule-dialog .e-start-input-container,
.e-schedule-dialog .e-end-input-container {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 12px;
  width: 100%;
}

.e-schedule-dialog .e-resources {
  padding-bottom: 12px;
  width: 100%;
}

.e-schedule-dialog .e-all-day-time-zone-row {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 14px;
  padding-top: 8px;
  width: 100%;
}

.e-schedule-dialog .e-subject-container,
.e-schedule-dialog .e-location-container,
.e-schedule-dialog .e-start-container,
.e-schedule-dialog .e-end-container,
.e-schedule-dialog .e-start-time-zone-container,
.e-schedule-dialog .e-end-time-zone-container {
  width: 50%;
}

.e-schedule-dialog .e-description,
.e-schedule-dialog .e-float-input .e-description {
  height: 50px;
  resize: vertical;
}

.e-schedule-dialog .e-repeat-container,
.e-schedule-dialog .e-input-group .e-input-group-icon.e-icon-disable {
  display: none;
}

.e-schedule-dialog .e-footer-content {
  margin-top: 4px;
}

.e-schedule-dialog.e-rtl {
  text-align: right;
}

.e-schedule-dialog.e-rtl .e-all-day-container {
  margin-left: 16px;
  margin-right: 0;
}

.e-schedule-dialog.e-rtl .e-subject-container,
.e-schedule-dialog.e-rtl .e-start-container,
.e-schedule-dialog.e-rtl .e-start-time-zone-container,
.e-schedule-dialog.e-rtl .e-description-label {
  padding-left: 8px;
  padding-right: 0;
}

.e-schedule-dialog.e-rtl .e-location-container,
.e-schedule-dialog.e-rtl .e-end-container,
.e-schedule-dialog.e-rtl .e-end-time-zone-container {
  padding-left: 0;
  padding-right: 8px;
}

.e-schedule-dialog.e-rtl .e-event-delete {
  float: right;
}

.e-ddl.e-popup .e-resource-template {
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  text-indent: 10px;
}

.e-ddl.e-popup .e-resource-template .e-resource-color {
  height: 14px;
  margin-top: 10px;
  width: 14px;
}

.e-bigger .e-ddl.e-popup .e-resource-template {
  padding: 0 15px;
}

.e-bigger .e-ddl.e-popup .e-resource-template .e-resource-color {
  height: 16px;
  margin-top: 14px;
  width: 16px;
}

.e-bigger .e-schedule-dialog .e-disable {
  display: none;
}

.e-bigger .e-schedule-dialog .e-dlg-header-content {
  padding-bottom: 8px;
}

.e-bigger .e-schedule-dialog .e-dlg-content {
  padding-bottom: 12px;
  position: relative;
}

.e-bigger .e-schedule-dialog .e-event-cancel,
.e-bigger .e-schedule-dialog .e-event-delete {
  border: none;
  box-shadow: none;
}

.e-bigger .e-schedule-dialog .e-footer-content .e-btn.e-event-delete {
  float: left;
  margin-left: 0;
}

.e-bigger .e-schedule-dialog .e-subject-container,
.e-bigger .e-schedule-dialog .e-start-container,
.e-bigger .e-schedule-dialog .e-start-time-zone-container,
.e-bigger .e-schedule-dialog .e-description-label {
  padding-right: 12px;
}

.e-bigger .e-schedule-dialog .e-location-container,
.e-bigger .e-schedule-dialog .e-end-container,
.e-bigger .e-schedule-dialog .e-end-time-zone-container {
  padding-left: 12px;
}

.e-bigger .e-schedule-dialog .e-all-day-container {
  padding-right: 16px;
}

.e-bigger .e-schedule-dialog .e-time-zone-row {
  display: none;
}

.e-bigger .e-schedule-dialog .e-time-zone-row.e-enable {
  display: -ms-flexbox;
  display: flex;
  height: 60px;
}

.e-bigger .e-schedule-dialog .e-title-location-row,
.e-bigger .e-schedule-dialog .e-start-end-row,
.e-bigger .e-schedule-dialog .e-start-input-container,
.e-bigger .e-schedule-dialog .e-end-input-container,
.e-bigger .e-schedule-dialog .e-time-zone-row {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 12px;
  width: 100%;
}

.e-bigger .e-schedule-dialog .e-resources {
  padding-bottom: 12px;
  width: 100%;
}

.e-bigger .e-schedule-dialog .e-all-day-time-zone-row {
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  padding-top: 0;
  width: 100%;
}

.e-bigger .e-schedule-dialog .e-subject-container,
.e-bigger .e-schedule-dialog .e-location-container,
.e-bigger .e-schedule-dialog .e-start-container,
.e-bigger .e-schedule-dialog .e-end-container,
.e-bigger .e-schedule-dialog .e-start-time-zone-container,
.e-bigger .e-schedule-dialog .e-end-time-zone-container {
  width: 50%;
}

.e-bigger .e-schedule-dialog .e-location-container,
.e-bigger .e-schedule-dialog .e-end-container,
.e-bigger .e-schedule-dialog .e-end-time-zone-container,
.e-bigger .e-schedule-dialog .e-start-container,
.e-bigger .e-schedule-dialog .e-start-time-zone-container,
.e-bigger .e-schedule-dialog .e-subject-container,
.e-bigger .e-schedule-dialog .e-description-row,
.e-bigger .e-schedule-dialog .e-repeat-container {
  padding-top: 0;
}

.e-bigger .e-schedule-dialog .e-all-day-time-zone-row {
  padding-bottom: 16px;
  padding-top: 10px;
}

.e-bigger .e-schedule-dialog .e-description,
.e-bigger .e-schedule-dialog .e-float-input .e-description {
  height: 50px;
  resize: vertical;
}

.e-bigger .e-schedule-dialog .e-time-zone-row,
.e-bigger .e-schedule-dialog .e-repeat-container,
.e-bigger .e-schedule-dialog .e-input-group .e-input-group-icon.e-icon-disable {
  display: none;
}

.e-bigger .e-schedule-dialog .e-footer-content {
  padding-top: 8px;
}

.e-bigger .e-schedule-dialog.e-rtl .e-all-day-container {
  margin-left: 20px;
  margin-right: 0;
}

.e-bigger .e-schedule-dialog.e-rtl .e-subject-container,
.e-bigger .e-schedule-dialog.e-rtl .e-start-container,
.e-bigger .e-schedule-dialog.e-rtl .e-start-time-zone-container,
.e-bigger .e-schedule-dialog.e-rtl .e-description-label {
  padding-left: 12px;
  padding-right: 0;
}

.e-bigger .e-schedule-dialog.e-rtl .e-location-container,
.e-bigger .e-schedule-dialog.e-rtl .e-end-container,
.e-bigger .e-schedule-dialog.e-rtl .e-end-time-zone-container {
  padding-left: 0;
  padding-right: 12px;
}

.e-bigger .e-schedule-dialog.e-rtl .e-event-delete {
  float: right;
}

.e-schedule-dialog.e-device .e-dlg-header,
.e-schedule-dialog.e-device .e-dlg-header * {
  width: 100%;
}

.e-schedule-dialog.e-device .e-dlg-header-content {
  background: #fafafa;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
}

.e-schedule-dialog.e-device .e-title-location-row,
.e-schedule-dialog.e-device .e-start-end-row,
.e-schedule-dialog.e-device .e-time-zone-row,
.e-schedule-dialog.e-device .e-start-input-container,
.e-schedule-dialog.e-device .e-end-input-container {
  display: inline;
}

.e-schedule-dialog.e-device .e-time-zone-row.e-enable {
  display: inline;
}

.e-schedule-dialog.e-device .e-subject-container,
.e-schedule-dialog.e-device .e-location-container,
.e-schedule-dialog.e-device .e-start-container,
.e-schedule-dialog.e-device .e-end-container,
.e-schedule-dialog.e-device .e-start-time-zone-container,
.e-schedule-dialog.e-device .e-end-time-zone-container {
  padding-left: 0;
  width: 100%;
}

.e-schedule-dialog.e-device .e-subject-container,
.e-schedule-dialog.e-device .e-start-container,
.e-schedule-dialog.e-device .e-start-time-zone-container,
.e-schedule-dialog.e-device .e-description-label {
  padding-right: 0;
}

.e-schedule-dialog.e-device .e-location-container,
.e-schedule-dialog.e-device .e-end-container,
.e-schedule-dialog.e-device .e-end-time-zone-container,
.e-schedule-dialog.e-device .e-start-container,
.e-schedule-dialog.e-device .e-start-time-zone-container,
.e-schedule-dialog.e-device .e-subject-container,
.e-schedule-dialog.e-device .e-description-row,
.e-schedule-dialog.e-device .e-repeat-container {
  padding-top: 20px;
}

.e-schedule-dialog.e-device .e-all-day-time-zone-row {
  padding-top: 20px;
}

.e-schedule-dialog.e-device .e-resources {
  padding-bottom: 0;
  padding-top: 20px;
}

.e-schedule-dialog.e-device .e-description,
.e-schedule-dialog.e-device .e-float-input .e-description {
  height: 60px;
  resize: vertical;
}

.e-schedule-dialog.e-device .e-all-day-time-zone-row {
  margin-top: 0;
  padding-bottom: 0;
}

.e-schedule-dialog.e-device .e-repeat-parent-row {
  padding-top: 8px;
}

.e-schedule-dialog.e-device .e-all-day-container {
  margin-right: 20px;
}

.e-schedule-dialog.e-device .e-title-header {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.e-schedule-dialog.e-device .e-save-icon,
.e-schedule-dialog.e-device .e-back-icon,
.e-schedule-dialog.e-device .e-forward-icon {
  cursor: pointer;
  line-height: normal;
}

.e-schedule-dialog.e-device .e-save-icon {
  text-align: right;
}

.e-schedule-dialog.e-device .e-time-zone-row,
.e-schedule-dialog.e-device .e-input-group .e-input-group-icon.e-icon-disable {
  display: none;
}

.e-schedule-dialog.e-device .e-repeat-container {
  display: block;
}

.e-schedule-dialog.e-device .e-icon-down-arrow {
  font-size: 10px;
}

.e-schedule-dialog.e-device.e-rtl .e-save-icon {
  text-align: left;
}

.e-schedule-dialog.e-device.e-rtl .e-all-day-container {
  margin-left: 20px;
  margin-right: 0;
}

.e-schedule-dialog.e-device.e-rtl .e-subject-container,
.e-schedule-dialog.e-device.e-rtl .e-start-container,
.e-schedule-dialog.e-device.e-rtl .e-start-time-zone-container,
.e-schedule-dialog.e-device.e-rtl .e-description-label {
  padding-left: 0;
}

.e-schedule-dialog.e-device.e-rtl .e-location-container,
.e-schedule-dialog.e-device.e-rtl .e-end-container,
.e-schedule-dialog.e-device.e-rtl .e-end-time-zone-container,
.e-schedule-dialog.e-device.e-rtl .e-all-day-container {
  padding-right: 0;
}

.e-schedule-dialog.e-device .e-recurrence-container {
  position: relative;
}

.e-schedule-dialog.e-device .e-recurrence-container.e-hide {
  display: none;
}

.e-schedule-dialog.e-device .e-recurrence-container .e-recurrence-edit-button {
  background: none;
  border: 0;
  box-shadow: none;
  margin: -3px 5px;
  position: absolute;
}

.e-schedule-dialog.e-device .e-recurrence-container .e-recurrence-edit-button .e-recurrence-edit.e-icons {
  font-size: 12px;
  position: relative;
  top: 1px;
}

/*! schedule quick popup */
.e-quick-popup-wrapper {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  max-width: 365px;
  min-width: 320px;
  opacity: 1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.e-quick-popup-wrapper .e-hidden {
  display: none;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper {
  position: absolute;
  right: 6px;
  top: 4px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-close {
  color: #000;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-edit:focus, .e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-edit:hover,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-delete:focus,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-delete:hover,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-close:focus,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-close:hover {
  background: #e0e0e0;
  color: #000;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-edit .e-close-icon,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-delete .e-close-icon,
.e-quick-popup-wrapper .e-cell-popup .e-popup-header .e-header-icon-wrapper .e-close .e-close-icon {
  font-size: 11px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
  padding: 28px 18px 8px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table {
  width: 100%;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table .e-schedule-form > span {
  margin-bottom: 4px;
  margin-bottom: 8px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table .e-subject {
  font-size: 22px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: #3f51b5;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper {
  padding: 5px 5px 2px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete {
  display: none;
  display: block;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close {
  color: #fff;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:focus, .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:hover,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:focus,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:hover,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close:focus,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit-icon,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete-icon,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close-icon {
  font-size: 11px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:disabled:hover,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:disabled:hover {
  background: none;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:disabled > .e-edit-icon,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:disabled > .e-delete-icon,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:disabled > .e-edit-icon,
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:disabled > .e-delete-icon {
  opacity: .5;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap {
  padding: 0 24px 16px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap .e-subject {
  cursor: default;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  max-height: 87px;
  color: #fff;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 8px 18px 26px 26px;
}

.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-close,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-close {
  background-color: transparent;
  border: 0;
}

.e-quick-popup-wrapper .e-cell-popup .e-date-time,
.e-quick-popup-wrapper .e-cell-popup .e-location,
.e-quick-popup-wrapper .e-cell-popup .e-time-zone,
.e-quick-popup-wrapper .e-cell-popup .e-description,
.e-quick-popup-wrapper .e-cell-popup .e-resource,
.e-quick-popup-wrapper .e-event-popup .e-date-time,
.e-quick-popup-wrapper .e-event-popup .e-location,
.e-quick-popup-wrapper .e-event-popup .e-time-zone,
.e-quick-popup-wrapper .e-event-popup .e-description,
.e-quick-popup-wrapper .e-event-popup .e-resource {
  color: rgba(0, 0, 0, 0.87);
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
}

.e-quick-popup-wrapper .e-cell-popup .e-date-time-icon,
.e-quick-popup-wrapper .e-cell-popup .e-location-icon,
.e-quick-popup-wrapper .e-cell-popup .e-time-zone-icon,
.e-quick-popup-wrapper .e-cell-popup .e-description-icon,
.e-quick-popup-wrapper .e-cell-popup .e-resource-icon,
.e-quick-popup-wrapper .e-event-popup .e-date-time-icon,
.e-quick-popup-wrapper .e-event-popup .e-location-icon,
.e-quick-popup-wrapper .e-event-popup .e-time-zone-icon,
.e-quick-popup-wrapper .e-event-popup .e-description-icon,
.e-quick-popup-wrapper .e-event-popup .e-resource-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  padding-right: 13px;
}

.e-quick-popup-wrapper .e-cell-popup .e-date-time-details,
.e-quick-popup-wrapper .e-cell-popup .e-location-details,
.e-quick-popup-wrapper .e-cell-popup .e-time-zone-details,
.e-quick-popup-wrapper .e-cell-popup .e-description-details,
.e-quick-popup-wrapper .e-cell-popup .e-resource-details,
.e-quick-popup-wrapper .e-event-popup .e-date-time-details,
.e-quick-popup-wrapper .e-event-popup .e-location-details,
.e-quick-popup-wrapper .e-event-popup .e-time-zone-details,
.e-quick-popup-wrapper .e-event-popup .e-description-details,
.e-quick-popup-wrapper .e-event-popup .e-resource-details {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  font-size: 14px;
}

.e-quick-popup-wrapper .e-popup-table td:last-child {
  padding-top: 0;
}

.e-quick-popup-wrapper .e-popup-footer {
  display: block;
  padding: 8px 18px 8px 22px;
  text-align: right;
  padding-right: 4px;
}

.e-quick-popup-wrapper .e-popup-footer .e-event-edit {
  margin-right: 8px;
}

.e-quick-popup-wrapper .e-popup-footer .e-event-edit,
.e-quick-popup-wrapper .e-popup-footer .e-event-delete {
  right: auto;
}

.e-quick-popup-wrapper .e-popup-footer .e-event-edit:disabled,
.e-quick-popup-wrapper .e-popup-footer .e-event-delete:disabled {
  color: rgba(0, 0, 0, 0.26);
}

.e-quick-popup-wrapper .e-popup-footer .e-event-details {
  border: none;
  margin-right: 8px;
  right: auto;
  text-transform: capitalize;
  text-transform: uppercase;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
  display: none;
}

.e-quick-popup-wrapper.e-rtl {
  text-align: right;
}

.e-quick-popup-wrapper.e-rtl .e-cell-popup .e-header-icon-wrapper {
  left: 6px;
  right: auto;
}

.e-quick-popup-wrapper.e-rtl .e-cell-popup .e-popup-footer {
  padding: 8px 18px 8px 8px;
  text-align: left;
}

.e-quick-popup-wrapper.e-rtl .e-popup-content .e-date-time-wrapper,
.e-quick-popup-wrapper.e-rtl .e-popup-content .e-location-details,
.e-quick-popup-wrapper.e-rtl .e-popup-content .e-time-zone-details,
.e-quick-popup-wrapper.e-rtl .e-popup-content .e-description-details,
.e-quick-popup-wrapper.e-rtl .e-popup-content .e-resource-details {
  margin-right: 10px;
}

.e-quick-popup-wrapper.e-device {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1004;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:focus, .e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit:hover,
.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:focus,
.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete:hover,
.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close:focus,
.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close:hover {
  background: none;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-edit,
.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-delete {
  display: block;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-close {
  margin-left: 0;
  margin-right: auto;
  -ms-flex-order: -1;
      order: -1;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-subject-wrap {
  padding: 12px 24px;
  padding: 24px;
}

.e-quick-popup-wrapper.e-device .e-event-popup .e-popup-content {
  padding: 12px 24px;
}

.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header {
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
}

.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-close,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-edit,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-delete {
  background-color: transparent;
  border: 0;
  height: 40px;
  width: 40px;
}

.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-close:focus, .e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-close:hover,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-edit:focus,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-edit:hover,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-delete:focus,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-delete:hover {
  background: none;
}

.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-subject {
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  line-height: 2.25;
  padding: 4px 5px;
  width: calc(100% - 95px);
}

.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-close-icon,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-edit-icon,
.e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-delete-icon {
  font-size: 14px;
}

.e-quick-popup-wrapper.e-device.e-rtl .e-event-popup .e-popup-header .e-close {
  margin-left: auto;
  margin-right: 0;
}

.e-bigger .e-quick-popup-wrapper .e-cell-popup .e-popup-content .e-popup-table .e-subject {
  font-size: 24px;
}

.e-bigger .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close-icon,
.e-bigger .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit-icon,
.e-bigger .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete-icon {
  font-size: 14px;
}

.e-bigger .e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close-icon,
.e-bigger .e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit-icon,
.e-bigger .e-quick-popup-wrapper.e-device .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete-icon {
  font-size: 14px;
}

.e-bigger .e-quick-popup-wrapper.e-device .e-multiple-event-popup .e-popup-header .e-subject {
  padding: 0 5px 0 8px;
  padding: 5px 5px 0 8px;
}

.e-appointment.e-schedule-event-clone {
  background: #3f51b5;
  border-radius: 2px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  width: 100px;
  z-index: 9;
}

.e-appointment.e-schedule-event-clone.e-drag-clone {
  cursor: move;
}

.e-appointment.e-schedule-event-clone .e-recurrence-icon,
.e-appointment.e-schedule-event-clone .e-recurrence-edit-icon,
.e-appointment.e-schedule-event-clone .e-indicator.e-left-icon,
.e-appointment.e-schedule-event-clone .e-indicator.e-right-icon,
.e-appointment.e-schedule-event-clone .e-event-resize.e-left-handler,
.e-appointment.e-schedule-event-clone .e-event-resize.e-right-handler {
  display: none;
}

.e-appointment.e-schedule-event-clone .e-appointment-details {
  -ms-flex: auto;
      flex: auto;
  overflow: hidden;
  padding: 0 4px;
  text-align: left;
}

.e-appointment.e-schedule-event-clone .e-appointment-details .e-inner-wrap {
  -ms-flex: auto;
      flex: auto;
  overflow: hidden;
}

.e-appointment.e-schedule-event-clone .e-appointment-details .e-subject {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  min-height: 18px;
  overflow: hidden;
  padding-top: 4px;
  text-overflow: ellipsis;
}

.e-appointment.e-schedule-event-clone .e-appointment-details .e-time {
  font-size: 11px;
  overflow: hidden;
  padding-top: 1px;
  text-overflow: ellipsis;
}

.e-appointment.e-schedule-event-clone .e-appointment-details .e-location {
  display: none;
}

.e-appointment.e-schedule-event-clone.e-month-event .e-appointment-details .e-subject {
  padding: 3px 2px;
}

.e-appointment.e-schedule-event-clone.e-month-event .e-appointment-details .e-time {
  display: none;
}

.e-vertical-view .e-all-day-appointment-wrapper .e-appointment.e-schedule-event-clone .e-appointment-details .e-subject {
  padding: 3px 0 1px 4px;
}

.e-vertical-view .e-all-day-appointment-wrapper .e-appointment.e-schedule-event-clone .e-appointment-details .e-time {
  display: none;
}

.e-bigger .e-schedule .e-month-view .e-current-date .e-date-header {
  width: 26px;
}

.e-more-popup-wrapper {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.4);
  opacity: 1;
  padding: 8px 0;
  width: 225px;
}

.e-more-popup-wrapper .e-more-appointment-wrapper {
  margin: 0 4px;
}

.e-more-popup-wrapper .e-more-event-popup {
  height: 100%;
  position: relative;
  width: 100%;
}

.e-more-popup-wrapper .e-more-event-header {
  height: 35px;
  margin: 0 8px 4px 14px;
}

.e-more-popup-wrapper .e-more-event-content {
  height: calc(100% - 35px);
  max-height: 300px;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.e-more-popup-wrapper .e-more-event-content .e-appointment-border {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-more-popup-wrapper .e-more-event-date-header {
  height: 100%;
  width: calc(100% - 25px);
}

.e-more-popup-wrapper .e-more-event-date-header .e-current-date {
  color: #e3165b;
}

.e-more-popup-wrapper .e-header-day {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 1;
  padding-bottom: 2px;
}

.e-more-popup-wrapper .e-header-date {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  line-height: 1;
  max-width: 15%;
}

.e-more-popup-wrapper .e-header-date:hover {
  cursor: pointer;
  text-decoration: underline;
}

.e-more-popup-wrapper .e-header-date:focus {
  text-decoration: underline;
}

.e-more-popup-wrapper .e-more-event-close {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 6px;
  width: 25px;
}

.e-more-popup-wrapper .e-more-event-close .e-close-icon {
  font-size: 9px;
}

.e-more-popup-wrapper .e-more-event-close .e-btn-icon {
  margin-top: 0;
}

.e-more-popup-wrapper .e-more-event-close:focus, .e-more-popup-wrapper .e-more-event-close:hover {
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #000;
}

.e-more-popup-wrapper .e-appointment {
  background: #3f51b5;
  border-radius: 2px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  line-height: 20px;
  margin-bottom: 2px;
  padding: 2px 0;
  width: 100%;
}

.e-more-popup-wrapper .e-appointment .e-subject {
  color: #fff;
  -ms-flex: auto;
      flex: auto;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  padding: 0 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-more-popup-wrapper .e-appointment .e-recurrence-icon,
.e-more-popup-wrapper .e-appointment .e-recurrence-edit-icon {
  line-height: 22px;
  padding: 0 2px;
}

.e-more-popup-wrapper .e-appointment.e-appointment-border, .e-more-popup-wrapper .e-appointment:focus {
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.e-more-popup-wrapper.e-device {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-more-popup-wrapper.e-device .e-more-event-content {
  max-height: unset;
}

.e-more-popup-wrapper.e-rtl .e-more-event-close {
  left: 6px;
  right: auto;
}

/*! schedule component theme */
.e-schedule {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.e-schedule .e-date-header-wrap {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0;
  position: relative;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule .e-vertical-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-vertical-view .e-date-header-wrap .e-all-day-cells {
  background-color: #fff;
}

.e-schedule .e-vertical-view .e-date-header-wrap .e-all-day-cells:hover {
  background: #f5f5f5;
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
  border-bottom-width: 0;
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells.e-week-number {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  padding-top: 10px;
  text-align: center;
  vertical-align: top;
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-resource-cells {
  border-bottom-color: transparent;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
  border-bottom-width: 0;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.54);
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  border-bottom-color: transparent;
}

.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.e-schedule .e-vertical-view .e-header-cells {
  background-color: #fff;
}

.e-schedule .e-vertical-view .e-header-cells.e-current-day {
  color: #e3165b;
}

.e-schedule .e-vertical-view .e-work-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
}

.e-schedule .e-vertical-view .e-work-cells:hover {
  background: #f5f5f5;
  color: #000;
}

.e-schedule .e-vertical-view .e-alternate-cells {
  border-bottom-style: dotted;
}

.e-schedule .e-vertical-view .e-work-hours {
  background-color: #fff;
}

.e-schedule .e-vertical-view .e-all-day-cells {
  background-color: #fff;
}

.e-schedule .e-vertical-view .e-all-day-cells.e-current-day {
  color: #e3165b;
}

.e-schedule .e-vertical-view .e-all-day-cells.e-selected-cell {
  background-color: #e0e0e0;
  color: #000;
}

.e-schedule .e-vertical-view .e-all-day-cells.e-selected-cell:hover {
  background-color: #e0e0e0;
}

.e-schedule .e-vertical-view .e-selected-cell {
  background-color: #e0e0e0;
  color: #000;
}

.e-schedule .e-vertical-view .e-selected-cell:hover {
  background-color: #e0e0e0;
}

.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time {
  color: #e3165b;
}

.e-schedule .e-vertical-view .e-current-timeline {
  border-top: 1px solid #e3165b;
}

.e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 1px dotted #e3165b;
}

.e-schedule .e-vertical-view.e-by-date .e-date-header-wrap table tbody td.e-header-cells {
  border-bottom-width: 1px;
}

.e-schedule .e-month-view .e-date-header-wrap table td:first-child,
.e-schedule .e-month-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-month-view .e-date-header-wrap table td,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
  color: #e3165b;
}

.e-schedule .e-month-view .e-work-cells,
.e-schedule .e-month-agenda-view .e-work-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-month-view .e-work-cells:hover,
.e-schedule .e-month-agenda-view .e-work-cells:hover {
  background: #f5f5f5;
  color: #000;
}

.e-schedule .e-month-view .e-work-days,
.e-schedule .e-month-agenda-view .e-work-days {
  background-color: #fff;
}

.e-schedule .e-month-view .e-other-month,
.e-schedule .e-month-agenda-view .e-other-month {
  color: rgba(0, 0, 0, 0.54);
}

.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  background-color: #e3165b;
  border-radius: 50%;
  color: #fff;
  width: 20px;
}

.e-schedule .e-month-view .e-selected-cell,
.e-schedule .e-month-agenda-view .e-selected-cell {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-month-view .e-selected-cell .e-more-indicator,
.e-schedule .e-month-agenda-view .e-selected-cell .e-more-indicator {
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-month-view .e-selected-cell:hover,
.e-schedule .e-month-agenda-view .e-selected-cell:hover {
  background-color: #e0e0e0;
}

.e-schedule .e-month-agenda-view .e-date-header-wrap table td {
  border-width: 0 0 1px 0;
  text-align: center;
}

.e-schedule .e-month-agenda-view .e-work-cells {
  border-width: 0;
}

.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  width: 24px;
}

.e-schedule .e-timeline-view .e-date-header-wrap table td:first-child,
.e-schedule .e-timeline-view .e-content-wrap table td:first-child,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td:first-child,
.e-schedule .e-timeline-month-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-timeline-view .e-content-wrap table tr:last-child td,
.e-schedule .e-timeline-month-view .e-content-wrap table tr:last-child td {
  border-bottom-width: 0;
}

.e-schedule .e-timeline-view .e-date-header-wrap table td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
  color: #e3165b;
}

.e-schedule .e-timeline-view .e-work-cells,
.e-schedule .e-timeline-month-view .e-work-cells {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells),
.e-schedule .e-timeline-month-view .e-work-cells:hover:not(.e-resource-group-cells) {
  background: #f5f5f5;
  color: #000;
}

.e-schedule .e-timeline-view .e-work-days,
.e-schedule .e-timeline-month-view .e-work-days {
  background-color: #fff;
}

.e-schedule .e-timeline-view .e-resource-group-cells,
.e-schedule .e-timeline-month-view .e-resource-group-cells {
  background-color: #fafafa;
}

.e-schedule .e-timeline-view .e-selected-cell,
.e-schedule .e-timeline-month-view .e-selected-cell {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.e-schedule .e-timeline-view .e-selected-cell:hover:not(.e-resource-group-cells),
.e-schedule .e-timeline-month-view .e-selected-cell:hover:not(.e-resource-group-cells) {
  background-color: #e0e0e0;
}

.e-schedule .e-timeline-view .e-work-hours {
  background-color: #fff;
}

.e-schedule .e-timeline-view .e-resource-group-cells {
  background-color: #fafafa;
}

.e-schedule .e-timeline-view .e-selected-cell {
  background-color: #e0e0e0;
  color: #000;
}

.e-schedule .e-timeline-view .e-selected-cell:hover {
  background-color: #e0e0e0;
}

.e-schedule .e-timeline-view .e-alternate-cells {
  border-left-style: dotted;
}

.e-schedule .e-timeline-view .e-header-row .e-time-cells {
  border-left-width: 0;
}

.e-schedule .e-timeline-view .e-clone-time-indicator,
.e-schedule .e-timeline-view .e-current-time {
  color: #e3165b;
  font-size: 11px;
}

.e-schedule .e-timeline-view .e-current-timeline {
  border-left: 1px solid #e3165b;
}

.e-schedule.e-rtl .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule.e-rtl .e-vertical-view .e-content-wrap table td:first-child {
  border-right-width: 0;
}

.e-schedule.e-rtl .e-vertical-view .e-date-header-wrap table tbody td,
.e-schedule.e-rtl .e-vertical-view .e-work-cells {
  border-width: 0 1px 1px 0;
}

.e-schedule.e-rtl .e-vertical-view .e-left-indent-wrap .e-header-cells,
.e-schedule.e-rtl .e-vertical-view .e-date-header-wrap .e-header-cells {
  border-bottom-width: 0;
}

.e-schedule.e-rtl .e-vertical-view .e-left-indent-wrap table tbody td,
.e-schedule.e-rtl .e-vertical-view .e-time-cells-wrap table tbody td {
  border-width: 0 0 1px 1px;
}

.e-schedule.e-rtl .e-month-view .e-date-header-wrap table td:first-child,
.e-schedule.e-rtl .e-month-view .e-content-wrap table td:first-child {
  border-right-width: 0;
}

.e-schedule.e-rtl .e-month-view .e-date-header-wrap table td {
  border-width: 0 1px 1px 0;
}

.e-schedule.e-rtl .e-month-view .e-work-cells,
.e-schedule.e-rtl .e-month-agenda-view .e-work-cells {
  border-width: 0 1px 1px 0;
}

.e-schedule.e-rtl .e-month-agenda-view .e-work-cells {
  border-width: 0;
}

.e-schedule.e-rtl .e-timeline-view .e-date-header-wrap table td:first-child,
.e-schedule.e-rtl .e-timeline-view .e-content-wrap table td:first-child,
.e-schedule.e-rtl .e-timeline-month-view .e-date-header-wrap table td:first-child,
.e-schedule.e-rtl .e-timeline-month-view .e-content-wrap table td:first-child {
  border-right-width: 0;
}

.e-schedule.e-rtl .e-timeline-view .e-date-header-wrap table td:last-child,
.e-schedule.e-rtl .e-timeline-view .e-content-wrap table td:last-child,
.e-schedule.e-rtl .e-timeline-month-view .e-date-header-wrap table td:last-child,
.e-schedule.e-rtl .e-timeline-month-view .e-content-wrap table td:last-child {
  border-left-width: 0;
}

.e-schedule.e-rtl .e-timeline-view .e-date-header-wrap table td,
.e-schedule.e-rtl .e-timeline-view .e-content-wrap table td,
.e-schedule.e-rtl .e-timeline-month-view .e-date-header-wrap table td,
.e-schedule.e-rtl .e-timeline-month-view .e-content-wrap table td {
  border-width: 0 1px 1px 0;
}

.e-schedule.e-rtl .e-timeline-view .e-alternate-cells {
  border-right-style: dotted;
}

.e-schedule.e-rtl .e-timeline-view .e-header-row .e-time-cells {
  border-right-width: 0;
}

.e-tooltip-wrap.e-schedule-error {
  background-color: #fcdbe4;
  border-color: #fcdbe4;
  z-index: 1000;
}

.e-tooltip-wrap.e-schedule-error .e-arrow-tip.e-tip-top {
  left: 44%;
}

.e-tooltip-wrap.e-schedule-error .e-arrow-tip-inner.e-tip-top,
.e-tooltip-wrap.e-schedule-error .e-arrow-tip-outer.e-tip-top {
  border-bottom: 8px solid #fcdbe4;
  color: #fcdbe4;
}

.e-tooltip-wrap.e-schedule-error .e-arrow-tip-outer.e-tip-bottom,
.e-tooltip-wrap.e-schedule-error .e-arrow-tip-inner.e-tip-bottom {
  border-top: 8px solid #fcdbe4;
  color: #fcdbe4;
}

.e-tooltip-wrap.e-schedule-error .e-tip-content {
  padding: 3px 6px;
}

.e-tooltip-wrap.e-schedule-error .e-tip-content,
.e-tooltip-wrap.e-schedule-error .e-tip-content label {
  color: #f44336;
}

/*! recurrence editor theme wise definitions*/
/*! Recurrence-Editor component layout */
.e-recurrenceeditor .e-editor {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
}

.e-recurrenceeditor .e-recurrence-table {
  table-layout: fixed;
  width: 100%;
}

.e-recurrenceeditor .e-recurrence-table.e-repeat-content-wrapper td:last-child {
  width: 27%;
}

.e-recurrenceeditor .e-recurrence-table.e-month-expand-wrapper td:first-child {
  width: 24%;
}

.e-recurrenceeditor .e-recurrence-table .e-repeat-content {
  display: inline-block;
  font-weight: normal;
  padding: 18px 0 0 8px;
}

.e-recurrenceeditor .e-recurrence-table .e-input-wrapper {
  float: none;
  width: 100%;
}

.e-recurrenceeditor .e-recurrence-table .e-week-position {
  position: relative;
  right: 16px;
}

.e-recurrenceeditor .e-recurrence-table .e-monthday-element {
  padding-left: 10px;
}

.e-recurrenceeditor .e-icons {
  height: 0;
}

.e-recurrenceeditor .e-input-wrapper-side.e-form-left {
  padding: 0 8px 10px 0;
}

.e-recurrenceeditor .e-form-left {
  padding: 0 8px 16px 0;
}

.e-recurrenceeditor .e-form-right,
.e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  padding: 0 0 10px 8px;
}

.e-recurrenceeditor .e-input-wrapper {
  float: left;
  width: 50%;
}

.e-recurrenceeditor .e-input-wrapper div {
  margin-bottom: 2.5%;
}

.e-recurrenceeditor .e-input-wrapper.e-end-on-date,
.e-recurrenceeditor .e-input-wrapper.e-end-on-count {
  padding-right: 0;
}

.e-recurrenceeditor.e-rtl .e-end-on > div,
.e-recurrenceeditor.e-rtl .e-month-expander > div > div {
  float: right;
}

.e-recurrenceeditor.e-rtl .e-form-left,
.e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-form-left {
  padding: 0 0 10px 8px;
}

.e-recurrenceeditor.e-rtl .e-form-right,
.e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-form-right {
  padding: 0 8px 10px 0;
}

.e-recurrenceeditor.e-rtl .e-recurrence-table .e-monthday-element {
  padding-left: 0;
}

.e-recurrenceeditor.e-rtl .e-week-position {
  padding-left: 16px;
  right: 0;
}

.e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-label,
.e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-non-week > .e-month-expander-label {
  padding-right: 0;
}

.e-recurrenceeditor.e-rtl .e-end-on-label {
  margin-bottom: 5px;
}

.e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-left: 8px;
  padding-right: 0;
}

.e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-date,
.e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-count {
  padding-left: 0;
  padding-right: 8px;
}

.e-recurrenceeditor.e-rtl .e-recurrenceeditor .e-recurrence-table.e-month-expand-wrapper td:first-child {
  width: 0;
}

.e-recurrenceeditor .e-days .e-week-expander-label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.e-recurrenceeditor .e-days button {
  border-radius: 50%;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  height: 35px;
  margin: 0 8px 10px;
  width: 35px;
}

.e-recurrenceeditor .e-hide-recurrence-element {
  display: none;
}

.e-recurrenceeditor .e-half-space {
  width: 20%;
}

.e-recurrenceeditor .e-year-expander {
  margin-bottom: 11px;
}

.e-recurrenceeditor .e-month-expander tr:first-child .e-input-wrapper {
  margin-bottom: 11px;
}

.e-recurrenceeditor .e-month-expander-checkbox-wrapper.e-input-wrapper {
  margin-top: -3px;
}

.e-recurrenceeditor .e-input-wrapper-side {
  float: left;
  padding: 16px 20px 0;
  width: 50%;
}

.e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-label {
  float: none;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 1px;
  padding-right: 16px;
}

.e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-right: 16px;
}

.e-recurrenceeditor .e-input-wrapper-side.e-non-week > .e-input-wrapper {
  margin: 0;
}

.e-recurrenceeditor .e-input-wrapper-side.e-non-week > .e-month-expander-label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 1px;
  padding-right: 16px;
}

.e-recurrenceeditor .e-input-wrapper-side .e-days .e-form-left {
  padding-bottom: 6px;
}

.e-recurrenceeditor .e-input-wrapper-side .e-non-week .e-form-left {
  padding-bottom: 12px;
}

.e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  margin-bottom: 11px;
}

.e-bigger .e-recurrenceeditor {
  padding: 0;
}

.e-bigger .e-recurrenceeditor .e-input-wrapper-side.e-form-left {
  padding: 0 12px 11px 0;
}

.e-bigger .e-recurrenceeditor .e-form-left {
  padding: 0 12px 14px 0;
}

.e-bigger .e-recurrenceeditor .e-form-right,
.e-bigger .e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  padding: 0 0 10px 12px;
}

.e-bigger .e-recurrenceeditor .e-recurrence-table .e-week-position {
  right: 24px;
}

.e-bigger .e-recurrenceeditor .e-input-wrapper-side .e-days .e-form-left {
  padding-bottom: 6px;
}

.e-bigger .e-recurrenceeditor .e-recurrence-table .e-monthday-element {
  padding-left: 70px;
}

.e-bigger .e-recurrenceeditor .e-week-position {
  padding-left: 55px;
  padding-right: 0;
}

.e-bigger .e-recurrenceeditor .e-input-wrapper-side.e-non-week > .e-month-expander-label {
  font-size: 12px;
  margin-bottom: 0;
}

.e-bigger .e-recurrenceeditor .e-end-on-label {
  margin-bottom: 0;
}

.e-bigger .e-recurrenceeditor .e-days .e-week-expander-label {
  font-size: 12px;
  margin-bottom: 8px;
}

.e-bigger .e-recurrenceeditor .e-input-wrapper-side .e-non-week .e-form-left {
  padding-bottom: 12px;
}

.e-bigger .e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-label {
  font-size: 12px;
  margin-bottom: 1px;
}

.e-bigger .e-recurrenceeditor .e-month-expander tr:first-child .e-input-wrapper,
.e-bigger .e-recurrenceeditor .e-year-expander,
.e-bigger .e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  margin-bottom: 11px;
}

.e-bigger .e-recurrenceeditor .e-recurrence-table.e-month-expand-wrapper td:first-child {
  width: 0;
}

.e-bigger .e-recurrenceeditor .e-days button {
  height: 40px;
  width: 40px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-form-left,
.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-form-left {
  padding: 0 0 10px 12px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-form-right,
.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-form-right {
  padding: 0 12px 10px 0;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-recurrence-table .e-monthday-element {
  padding-left: 0;
  padding-right: 64px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-label,
.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-non-week > .e-month-expander-label {
  padding-right: 0;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-end-on-label {
  margin-bottom: 5px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-left: 12px;
  padding-right: 0;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-date,
.e-bigger .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-count {
  padding-left: 0;
  padding-right: 12px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-recurrence-table .e-week-position {
  right: 33px;
}

.e-bigger .e-recurrenceeditor.e-rtl .e-week-position {
  padding-left: 46px;
}

.e-device .e-recurrenceeditor .e-recurrence-table.e-repeat-content-wrapper td:last-child {
  width: 25%;
}

.e-device .e-recurrenceeditor .e-recurrence-table.e-month-expand-wrapper td:first-child {
  width: 20%;
}

.e-device .e-recurrenceeditor .e-week-expander-label {
  margin-bottom: 6px;
}

.e-device .e-recurrenceeditor .e-month-expander-label {
  font-size: 12px;
  margin-bottom: 5px;
}

.e-device .e-recurrenceeditor .e-footer-content {
  padding: 12px;
}

.e-device .e-recurrenceeditor .e-form-left,
.e-device .e-recurrenceeditor .e-input-wrapper-side.e-form-left {
  padding: 0 3px 10px 0;
}

.e-device .e-recurrenceeditor .e-form-right,
.e-device .e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  padding: 0 0 10px 3px;
}

.e-device .e-recurrenceeditor .e-input-wrapper.e-end-on-date,
.e-device .e-recurrenceeditor .e-input-wrapper.e-end-on-count {
  padding-left: 10px;
  padding-right: 0;
}

.e-device .e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-right: 10px;
}

.e-device .e-recurrenceeditor.e-end-on {
  padding-right: 0;
}

.e-device .e-recurrenceeditor.e-end-on .e-end-on-label {
  float: none;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 1px;
}

.e-device .e-recurrenceeditor.e-end-on .e-end-on-left {
  padding-right: 0;
}

.e-device .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-right: 0;
}

.e-device .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-date,
.e-device .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-count {
  padding-left: 0;
  padding-right: 10px;
}

.e-device .e-recurrenceeditor.e-rtl .e-recurrence-table .e-monthday-element {
  padding-left: 0;
}

.e-device .e-recurrenceeditor.e-rtl .e-week-position {
  padding-left: 16px;
  padding-right: 0;
}

.e-device .e-recurrenceeditor .e-recurrence-table .e-monthday-element {
  padding-left: 20px;
}

.e-device .e-recurrenceeditor .e-week-position {
  padding-left: 0;
  padding-right: 0;
}

.e-device .e-recurrenceeditor .e-week-position {
  padding-left: 0;
}

.e-device.e-recurrence-dialog .e-dlg-header-content {
  background: none;
  box-shadow: none;
  padding-bottom: 10px;
}

.e-device.e-recurrence-dialog .e-editor .e-input-wrapper-side.e-end-on .e-end-on-label {
  margin-bottom: 1px;
}

.e-device.e-recurrence-dialog .e-footer-content {
  padding: 16px 8px;
}

@media (max-width: 580px) {
  .e-recurrenceeditor {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .e-recurrenceeditor .e-editor {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .e-recurrenceeditor .e-editor > .e-input-wrapper.e-form-left {
    margin-top: 0;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-non-week > .e-month-expander-label,
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-end-on .e-end-on-label {
    margin-bottom: 1px;
  }
  .e-recurrenceeditor .e-editor > div {
    margin-top: 20px;
  }
  .e-recurrenceeditor .e-editor > .e-input-wrapper {
    width: 100%;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-end-on {
    width: 100%;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-end-on .e-input-wrapper {
    width: 50%;
  }
  .e-recurrenceeditor .e-editor .e-form-left,
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-form-left {
    padding: 0 0 10px;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper.e-end-on-date,
  .e-recurrenceeditor .e-editor .e-input-wrapper.e-end-on-count {
    padding-left: 10px;
    padding-right: 0;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-end-on .e-end-on-left {
    padding-right: 10px;
  }
  .e-recurrenceeditor .e-editor .e-form-right,
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-form-right {
    padding-left: 0;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-days {
    width: 100%;
  }
  .e-recurrenceeditor .e-editor .e-input-wrapper-side.e-non-week {
    width: 100%;
  }
  .e-recurrenceeditor.e-rtl .e-input-wrapper-side.e-end-on .e-end-on-left {
    padding-right: 0;
  }
  .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-date,
  .e-recurrenceeditor.e-rtl .e-input-wrapper.e-end-on-count {
    padding-left: 0;
    padding-right: 10px;
  }
}

/*! Recurrence-Editor component theme */
